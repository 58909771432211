import React from "react";
import CustomerBasicDetails from "./CustomerBasicDetails";
import CustomerFollowup from "./CustomerFollowup";
import { useState, useEffect, useContext, useMemo } from "react";
import { useNavigate } from "react-router";
import axios from "axios";
import { ImCancelCircle } from "react-icons/im";
import { API_URL } from "../api/api_settings";
import Swal from "sweetalert2";
import PaginationComponent from "../pagination/Pagination";
import Pagination from "react-js-pagination";
import "./pagination.css";
import { useLocation } from "react-router";
import { SidebarContext } from "../context/ContextState";
import { Blocks, ColorRing, InfinitySpin } from "react-loader-spinner";
import "./customer.css";
import { toast } from "react-toastify";
import Firstcalllisting from "./Firstcalllisting";
import { api } from "../lib/base";

export default function Firstfollowupcalllisting() {
  const navigate = useNavigate();

  const location = useLocation();
  const {
    sidebarOpen,
    toggleSidebar,
    counts,
    tab,
    tabopen,
    viewch,
    disabled,
    viewdisable,
    disab,
    viewdisables,
    changeTry,
    viewdisab,
    tri,
    changeTry1,
    tri1,
  } = useContext(SidebarContext);

  function cancels() {
    document.getElementById("edits").style.display = "none";
  }
  const [checkedval, setCheckedval] = useState([]);
  const [customerBasic, setCustomerBasic] = useState(false);
  const [customerFollow, setCustomerFollow] = useState(false);
  const [call_status_data, setCallStatusdata] = useState({});
  const [viewhide, setViewHide] = useState("");
  // const [disabled, setDisabled] = useState(false);
  const [disable, setDisable] = useState(false);
  const [foll_stat, setFoll_stat] = useState("");
  const [cust_id, setcustid] = useState();
  const [disables, setDisables] = useState("views");
  const [loading, setLoading] = useState(false);
  const [button_dis, setButtonDis] = useState(false);
  const [followstatus, setFollowstatus] = useState("");
  const [options, setoptions] = useState();
  const [vi,setVi] = useState(true)

  const [value, setValue] = useState({
    call_status: "0",
    follow_up_status: "",
    call_connection_status: "",
    dwms_intrst: "",
    current_status: "",
    general: "",
    skills: "",
    job_preference: "",
    experience: "",
    not_intrstd_reason: "",
  });

  const [pages, setpages] = useState({
    CurrentPage: 1,
    lastPages: 0,
    pagination_item: [],
    totalPages: 0,
  });

  const [dat, setDatas] = useState([]);
  const [da, setDatass] = useState({});
  const [dumdat, setDumdatas] = useState([]);


  const onChange = (e, field_name = null) => {
    setValue({
      ...value,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };

  const check_data = [
    { id: 1, name: "Skills", is_check: false },
    { id: 2, name: "Experience", is_check: false },
    { id: 3, name: "Job preference", is_check: false },
    { id: 4, name: "General Information", is_check: false },
  ];
  const [isCheckChannel, setIsCheckChannel] = useState([]);
  const [isCheckChannel1, setIsCheckChannel1] = useState([]);

  const handleCheck = (e) => {
    const checked_category = e.target.checked;

    const c_value = e.target.value;
    if (checked_category == true) {
      setIsCheckChannel([...isCheckChannel, c_value]);
    } else if (checked_category == false) {
      setIsCheckChannel(isCheckChannel.filter((item) => item !== c_value));
    }
  };


  
  const handleCheck1 = (e) => {
    const checked_category = e.target.checked;

    const c_value = e.target.value;
    if (checked_category == true) {
      setIsCheckChannel1([...isCheckChannel1, c_value]);
    } else if (checked_category == false) {
      setIsCheckChannel1(isCheckChannel1.filter((item) => item !== c_value));
    }
  };


  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [firstcall, setFirstCall] = useState([]);

  const [li, setLi] = useState([]);
  const [li1, setLi1] = useState([]);

  const [ids, setIds] = useState([]);
  const handleClick = (index) => {
    var new_data = [...dat];
    new_data[index].is_check = !new_data[index]?.is_check;
    setDatas(new_data);
    let checkValues = new_data?.filter((item) => item?.is_check !== false);
    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });
    console.log(tr, "????dfhdgvdfgvdf");
    setIds(tr);
  };

  const handleClicks2 = (e)=>{
    const checked_category = e.target.checked;
  
    const c_value = e.target.value;
    if (checked_category == true) {
      setLi1([...li1, c_value]);
    } else if (checked_category == false) {
      setLi1(li1.filter((item) => item !== c_value));
    }}


    function lists1(opt) {
      console.log(opt, "opt");
  
      api
        .post(
          `careersupportmeta`,
          
            {
              followup_type: opt == 0 ? 1 : opt == 1 ? 2 : opt == 2 ? 3 : null,
            },
          
          true
        )
        .then(async function ([success, response]) {
          console.log(response, "e111111111");
  
          if (response?.status == "success") {
            let datas = response?.data || response?.message;
            setDatass(datas);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
  const mem = useMemo(() => {
    // ...
  }, [
    options,
    location,
    pages.CurrentPage,
    sidebarOpen,
    tabopen,
    toggleSidebar,
    loading,
  ]);

  useEffect(() => {
    getCustomList(pages.CurrentPage);
    api
      .post(
        `profilemeta`,
        {
          followup_type: 2,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          let datas = response?.data;
          datas?.map((item) => {
            item.is_check = false;
          });

          setDatas(datas);
        }
      })
      .catch((err) => {
        console.log(err);
      });


  }, [mem, value]);
  const getCustomList = async (page = 1) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      //  body,
    };

    setLoading(true);

    fetch(
      `${API_URL}customer?page=${page}&profile_completion_status=1`,
      requestOptions
    )
      .then((response) => {
        if (response.status == 200) {
          response.json().then((responseData) => {
            setUsers(responseData?.data?.data);
            setFollowstatus(responseData?.data?.followup_status);
            setpages({
              ...pages,
              CurrentPage: responseData?.data?.current_page,
              lastPages: responseData?.data?.last_page,
              Per_page: responseData?.data?.per_page,
              totalPages: responseData?.data?.total,
            });
            setLoading(false);
          });
        }

        console.log(response?.status);
      })
      .catch((e) => {
        console.log(e, ">>>");
        setLoading(true);
      });
  };

  function customerbasic(cust_id, call_status) {
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      //  body,
    };
    fetch(`${API_URL}customer/` + cust_id, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        setFirstCall(responseData?.data);
        setoptions(responseData?.data?.followup_status);
        lists1(responseData?.data?.followup_status);

        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
      });

    setCustomerBasic(true);
  }

  const handleSubmit = (data) => {
    var foll_stat = "";

    setCallStatusdata(data?.call_status);
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body: JSON.stringify({
        call_status: data?.call_status,
        cust_id: data.id,
      }),
    };

    if (data.followup_status == 0) {
      foll_stat = "first_call_establish";
    } else if (data.followup_status == 1) {
      foll_stat = "first_followup_establish";
    } else if (data.followup_status == 2) {
      foll_stat = "second_followup_establish";
    }

    fetch(`${API_URL}${foll_stat}`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          if (data?.call_status != 1) {
            getCustomList(pages.CurrentPage);
            viewch();
            setCustomerBasic(false);
            counts();
            document.getElementById("det").style.display = "none";
            document.getElementById("details").style.display = "none";
            setButtonDis(true);
            document.getElementsByClassName("disabled").style.opacity = "1"; // setCustomerBasic(false);
          }

          if (responseData?.message == "User Inactive.") {
            getCustomList(pages.CurrentPage);
            viewch();
            setCustomerBasic(false);
            counts();
            document.getElementById("det").style.display = "none";
            document.getElementById("details").style.display = "none";
            setButtonDis(true);
            document.getElementsByClassName("disabled").style.opacity = "1";
          }

          if (data.call_status == 1) {
            changeTry();
          }
          checkval(data?.id);
          setVi(false)

        }

        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
      });

    setCustomerFollow(false);

    if (data.call_status == 1) {
      setButtonDis(true);
    }
  };

  const followupsubmit = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body: JSON.stringify({
        customer_profile_id: data?.cust_id,
        call_connection_status: "",
        education: data?.education,
        intrested_in_dwms: data?.dwms_interest,
        not_intrested_reason: data?.not_intrstd_reason,
        first_call_data: data?.first_call_datas,
      }),
    };

    fetch(`${API_URL}firstcall`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          if (tri == false) {
            viewdisable();
          } else if (tri1 == false) {
            viewdisables();
          } else {
            viewdisable();
            viewdisables();
          }
          toast.success(responseData?.message);
          getCustomList(pages.CurrentPage);
          toggleSidebar("firstcall");
          counts();
          setDisables("views");
          setoptions(data?.options);
          viewdisab();
          setDatas([])
          setIds([])
          setLi([])
          setLi1([])


          // window.location.reload();
        }

        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
        toast.error("Something Went Wrong, Please Try Again");
      });
  };

  /* firstfollow up questions*/
  const followupsubmit1 = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body: JSON.stringify({
        customer_profile_id: data?.cust_id,
        job_visibility: data?.job_visibility,
        intrested_in_career_support: data?.intrested_in_career_support,
        first_Followup_call_data: ids?.length > 0 ? ids : dumdat,
        career_support_data:data?.career_support_data

      }),
    };

    fetch(`${API_URL}first_followup`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          toast.success(responseData?.message);
          getCustomList(pages.CurrentPage);
          counts();
          setDisables("views");
          setoptions(data?.options);
          viewdisables();
          viewdisable();
          changeTry();
          changeTry1();
          tab();
          setButtonDis(false);
          document.getElementById("det").style.display = "none";
          setCustomerBasic(false);
          setDatas([])
          setIds([])
          setLi([])
          setLi1([])


        } else {
          toast.error("Something Went Wrong, Please Try Again");
        }
        // window.location.reload();
        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
        toast.error("Something Went Wrong, Please Try Again");
      });
  };
  /* second followup questions
   */
  const followupsubmit2 = (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body: JSON.stringify({
        customer_profile_id: data?.cust_id,
        availed_career_service: data?.availabale_career_service,
        second_Followup_call_data: data?.checkedvalue,
        profile_incomplete_reason: data?.profile_incomplete_reason,
        specific_services: data?.specific_service,
      }),
    };

    fetch(`${API_URL}second_followup`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          toast.success(responseData?.message);

          getCustomList(pages.CurrentPage);
          toggleSidebar("second");
          counts();
          setDisables("views");
          setoptions(data?.options);
          viewdisab();
          setDatas([])
          setIds([])
          setLi([])

        }
        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
        toast.error("Something Went Wrong, Please Try Again");
      });
  };
  const checkval = async (id) => {
    api
      .get(`firstCallCompleteStep?cust_id=${id}`, {}, true)
      .then(async function ([success, response]) {
        const editSocialId = dat;

        const data = response?.data;
        data?.map((item) => {
          item.is_check = true 
          item.dis = 1;
          let tem = item?.id;
          setDumdatas(dumdat => [...dumdat,tem]);
        });
        console.log(response?.data, "data1");
        console.log(editSocialId, "data2");
        const v = [...data, ...editSocialId];
        getUnique(v, "id");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  function getUnique(arr, index) {
    const unique = arr
      .map((e) => e[index])

      // store the keys of the unique objects
      .map((e, i, final) => final.indexOf(e) === i && i)

      // eliminate the dead keys & store unique objects
      .filter((e) => arr[e])
      .map((e) => arr[e]);

     setDatas(unique);
  }

  return (
    <div className="right-content-wrap">
      <div className="list-of-customers">
        <div className="card list-card-head p-3">
          <div className="d-flex align-items-center justify-content-between flex-wrap  gap-2">
            <div className="main-title">
              <h5 className="mb-0">First Followup Call Listing</h5>
            </div>
            {/* <div class="head-menu-buttons d-flex align-items-center gap-3 flex-wrap">
              <a href="" className="px-4">
                Pipeline
              </a>
              <a href="" className="px-4">
                FIle Import
              </a>
              <a href="" className="px-4">
                File Export
              </a>
            </div> */}
          </div>
        </div>

        {loading ? (
          <ColorRing
            visible={true}
            height="150"
            width="150"
            ariaLabel="blocks-loading"
            wrapperStyle={{ marginLeft: "40%", marginTop: "20%" }}
            wrapperClass="blocks-wrapper"
          />
        ) : (
          <div className="row mt-4 gx-3 gy-3 ">
            {users?.length > 0 ? (
              <div className="col-lg-6 col-md-12">
                <div className="table-wrap">
                  <table className="w-100">
                    <thead>
                      <th>Sl No</th>
                      <th>Dwms ID</th>
                      <th>Jobseeker Name</th>
                      <th>Followup</th>
                      <th>Call Date</th>
                      <th>Actions</th>
                    </thead>
                    <tbody>
                      {users?.map((item,index) => {
                        return (
                          <>
                            <tr>
                            <td>{`${index+1 == 10 ? (pages.CurrentPage != null ? pages.CurrentPage : 1 - 1) * (index+1) : pages.CurrentPage != null ? pages.CurrentPage-1 +""+ (index+1) : null}`}</td>

                              <td>{item.dwms_profile_id}</td>
                              <td>
                                <div className="d-flex align-items-center account-image-details">
                                  <img
                                    src="images/userimage.png"
                                    class="me-2"
                                  />
                                  <div className="name_and_mail">
                                    <h6 className="mb-0">{item.jobseeker_name}</h6>
                                  </div>
                                </div>
                              </td>
                              <td>
                                {item?.followup_status == 0
                                  ? "First Call"
                                  : item?.followup_status == 1
                                  ? "First Followup"
                                  : item?.followup_status == 2
                                  ? "Second Followup"
                                  : item?.followup_status == 3
                                  ? "Completed"
                                  : "nill"}
                              </td>
                              <td>{item?.call_date ? item?.call_date : "-"}</td>
                              <td id="view">
                                <a
                                  href="#"
                                  className={
                                    disabled ? " disabled" : "notdisabled"
                                  }
                                  onClick={() => {
                                    setcustid(item?.id)(
                                      customerbasic(
                                        item?.id,
                                        item?.call_status,
                                        item?.follow_up_status
                                      ),

                                      viewdisable()
                                    );
                                  }}
                                >
                                  {item?.followup_status == 3 ? null : "View"}
                                </a>
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div style={{ marginTop: "5%" }}>
                  <Pagination
                    className="paginations"
                    activePage={pages.CurrentPage ? pages.CurrentPage : 0}
                    itemsCountPerPage={pages.Per_page ? pages.Per_page : 0}
                    totalItemsCount={pages.totalPages ? pages.totalPages : 0}
                    onChange={(pageNumber) => {
                      getCustomList(pageNumber);
                    }}
                    pageRangeDisplayed={5}
                    itemClass="ul-li"
                    linkClass="page-link"
                    prevPageText="<"
                    nextPageText=">"
                    firstPageText="First"
                    lastPageText="Last"
                  />
                </div>
              </div>
            ) : (
              <h3 style={{ marginLeft: "35%" }}>No Records Found</h3>
            )}
            {/* box */}
            {/* <PaginationComp
                                                    pages={pages}
                                                    handleLeft={handleLeft}
                                                    handleRight={handleRight}
                                                    handlePagination={handlePagination}
                                                /> */}

            <div className="col-md-12 col-lg-6" id="edits">
              <div className="row gx-3 gy-3 right-top">
                {customerBasic == true ? (
                  <>
                    <CustomerBasicDetails
                      handleSubmit={handleSubmit}
                      data={firstcall}
                      onChange={onChange}
                      button_dis={button_dis}
                      checkedval={checkedval}
                      setButtonDis={setButtonDis}

                    />
                  </>
                ) : (
                  // <div className="col-md-12 col-lg-6">
                  //   <div className="row gx-3 gy-3 right-top">

                  //   </div>
                  // </div>
                  ""
                )}

                {tabopen == true ? (
                  <CustomerFollowup
                    followupsubmit={followupsubmit}
                    followupsubmit1={followupsubmit1}
                    followupsubmit2={followupsubmit2}
                    onChange={onChange}
                    value={value}
                    data={firstcall}
                    datass={dat}
                    options={options}
                    handleClick={handleClick}
                    handleClicks2={handleClicks2}
                    li={li}
                    li1={li1}
                    vi={vi}
                    da={da}
                    setLi={setLi}
                    check_data={check_data}
                    handleCheck={handleCheck}
                    follow_up_status={followstatus}
                    checkedval={checkedval}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
