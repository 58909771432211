import React from "react";
import { Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router";
const PrivateRoute = ({ loginStatus, component: Component, ...rest }) => {
  const [authUser, setAuthUser] = useState(true);
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN") !== null) {
      setAuthUser(true);
    } else {
      setAuthUser(false);
    }
  }, [loginStatus, authUser]);
  const location = useLocation();
  return (
    <>
      {authUser ? (
        <Outlet />
      ) : (
        <Navigate to="/" replace state={{ from: location }} />
      )}
    </>
  );
};
export default PrivateRoute;