import { useState, useEffect, useContext } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../api/api_settings";
import { useNavigate } from "react-router-dom";
import Firstcalllisting from "../customer/Firstcalllisting";
import Firstfollowupcalllisting from "../customer/First_followup_list";
import { SidebarContext } from "../context/ContextState";
import { useLocation } from "react-router";
import constants from "../constants/constant";

export default function SideBar(data) {
  const navigate = useNavigate();
  const location = useLocation();
  const [reloads, setReloads] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setName(localStorage.getItem("username"));
    setEmail(localStorage.getItem("email"));
  });

  const {
    sidebarOpen,
    toggleSidebar,
    firstcallcount,
    customercount,
    firstcallfollowupcount,
    secondcallfollowupcount,
    callcount,
    counts,
    closeallstates
  } = useContext(SidebarContext);

  console.log(customercount,"vyuitfuyi")
 
  const rel = () => {
    setReloads(sidebarOpen);
  };

  useEffect(() => {
    rel();
   
  }, [reloads, toggleSidebar.sidebarOpen]);

 
  const go_page = (data) => {
    navigate(data);
  };
  const role_id = localStorage.getItem("role_id");

  return (
    <>
    
      <div class="col-md-2 desktop-sidebar d-none d-md-block d-lg-block d-xl-block d-xxl-block">
        <div class="sidebar-wrap">
          <ul class="nav flex-column">
            {/* Admin Routs */}
            {role_id == constants.USER_ROLES.ADMIN ? (
              <>
               <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_dashboard"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_dashboard"
                  >
                    <i class="bi bi-columns-gap me-3"></i> Admin Dashboard
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_agent_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_agent_list"
                  >
                    <i class="bi bi-person me-3"></i> Intern Listing
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_customer_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_customer_list"
                  >
                   <i class="bi bi-person me-3"></i>Jobseeker Listing
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/reports"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/reports"
                  >
                   <i class="bi bi-file-earmark-fill me-3
                   "></i>Reports
                  </Link>
                </li>
              </>
            ) : (
              <>
                {/* Agnet side bar */}
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/dashboard"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/dashboard"
                  >
                    <i class="bi bi-columns-gap me-3"></i>Intern Dashboard 
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/customerlisting"
                        ? "nav-link active"
                        : "nav-link"
                    }

                    onClick={()=>closeallstates()}
                    aria-current="page"
                    to="/customerlisting"
                  >
                    <i class="bi bi-person me-3"></i> Jobseeker (
                    {customercount?.data?.totalactivecustomercount ? customercount?.data?.totalactivecustomercount  : 0})
                  </Link>
                </li>

                <li class="nav-item">
                  <a
                    className={
                      location?.pathname == "/firstcalllisting" ||
                      location?.pathname ==
                        "/first_call_followup_customer_list" ||
                      location?.pathname ==
                        "/second_call_followup_customer_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={()=>closeallstates()}
                    data-bs-toggle="collapse"
                    href="#sidebar-collapse01"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebar-collapse01"
                  >
                    <i class="bi bi-chat-left me-3"></i>Follow ups
                  </a>
                  <ul
                    class="collapse show list-unstyled ps-xl-5 ps-lg-5 ps-md-2 ps-2"
                    id="sidebar-collapse01"
                  >
                    <li className="sub-menu" class="sub-menu ">
                      <a
                        className={
                          location?.pathname == "/firstcalllisting"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/firstcalllisting");
                        }}
                      >
                        First Call Pending ({customercount?.data?.totafirstcallcount ? customercount?.data?.totafirstcallcount : 0})
                      </a>
                    </li>
                    <li class="sub-menu">
                      <a
                        className={
                          location?.pathname ==
                          "/first_call_followup_customer_list"
                            ? "nav-link active"
                            : "nav-link"
                        }
                       
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/first_call_followup_customer_list");
                        }}
                      >
                        First Follow up Pending ({customercount?.data?.totalfirstfollowupcount ? customercount?.data?.totalfirstfollowupcount : 0})
                      </a>
                    </li>
                    <li class="sub-menu">
                      <a
                        className={
                          location?.pathname ==
                          "/second_call_followup_customer_list"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/second_call_followup_customer_list");
                        }}
                      >
                        Second Follow up Pending ({customercount?.data?.secondfollowupcount ? customercount?.data?.secondfollowupcount : 0})
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
        </div>
      </div>

      
      {/* <!--mobile sidebar --> */}
      <div class="offcanvas offcanvas-start mobile-sidebar" tabindex="-1" id="mobile-sidebar" aria-labelledby="mobile-sidebar-label">
        <div class="offcanvas-header">
          <a class="navbar-brand" href="#">
            <img src="images/Oricoms.png" alt="icon"/>
          </a>
          <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
        <div class="offcanvas-body">
          <div class="sidebar-wrap position-relative w-100">
           <hr/>
            <div class=" account-dropdown">
              <a class="nav-link">
                <div class="d-flex align-items-center avatar-dropdown-image">
                  <img src="images/accountpic.png" class="me-3"/>
                  <div class="name_and_mail">
                      <h6 class="mb-0">{name}<br/><small>{email}</small></h6> 
                  </div>
              </div>
              </a>       
          </div>

          <hr/>

          <ul class="nav flex-column">
            {/* Admin Routs */}
            {role_id == constants.USER_ROLES.ADMIN ? (
              <>
               <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_dashboard"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_dashboard"
                  >
                    <i class="bi bi-columns-gap me-3"></i> Admin Dashboard
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_agent_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_agent_list"
                  >
                    <i class="bi bi-person me-3"></i> Intern Listing
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/admin_customer_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/admin_customer_list"
                  >
                   <i class="bi bi-person me-3"></i>Jobseeker Listing
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/reports"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/reports"
                  >
                   <i class="fa-solid fa-file me-3"></i>Reports
                  </Link>
                </li>
              </>
            ) : (
              <>
                {/* Agnet side bar */}
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/dashboard"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    aria-current="page"
                    to="/dashboard"
                  >
                    <i class="bi bi-columns-gap me-3"></i>Intern Dashboard 
                  </Link>
                </li>
                <li class="nav-item">
                  <Link
                    className={
                      location?.pathname == "/customerlisting"
                        ? "nav-link active"
                        : "nav-link"
                    }

                    onClick={()=>closeallstates()}
                    aria-current="page"
                    to="/customerlisting"
                  >
                    <i class="bi bi-person me-3"></i> Jobseeker (
                    {customercount?.data?.totalactivecustomercount ? customercount?.data?.totalactivecustomercount  : 0})
                  </Link>
                </li>

                <li class="nav-item">
                  <a
                    className={
                      location?.pathname == "/firstcalllisting" ||
                      location?.pathname ==
                        "/first_call_followup_customer_list" ||
                      location?.pathname ==
                        "/second_call_followup_customer_list"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={()=>closeallstates()}
                    data-bs-toggle="collapse"
                    href="#sidebar-collapse01"
                    role="button"
                    aria-expanded="false"
                    aria-controls="sidebar-collapse01"
                  >
                    <i class="bi bi-chat-left me-3"></i>Follow ups
                  </a>
                  <ul
                    class="collapse show list-unstyled ps-xl-5 ps-lg-5 ps-md-2 ps-2"
                    id="sidebar-collapse01"
                  >
                    <li className="sub-menu" class="sub-menu ">
                      <a
                        className={
                          location?.pathname == "/firstcalllisting"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/firstcalllisting");
                        }}
                      >
                        First Call ({customercount?.data?.totafirstcallcount ? customercount?.data?.totafirstcallcount : 0})
                      </a>
                    </li>
                    <li class="sub-menu">
                      <a
                        className={
                          location?.pathname ==
                          "/first_call_followup_customer_list"
                            ? "nav-link active"
                            : "nav-link"
                        }
                       
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/first_call_followup_customer_list");
                        }}
                      >
                        First Followup ({customercount?.data?.totalfirstfollowupcount ? customercount?.data?.totalfirstfollowupcount : 0})
                      </a>
                    </li>
                    <li class="sub-menu">
                      <a
                        className={
                          location?.pathname ==
                          "/second_call_followup_customer_list"
                            ? "nav-link active"
                            : "nav-link"
                        }
                        href="#"
                        onClick={() => {
                          closeallstates()
                          go_page("/second_call_followup_customer_list");
                        }}
                      >
                        Second Followup({customercount?.data?.secondfollowupcount ? customercount?.data?.secondfollowupcount : 0})
                      </a>
                    </li>
                  </ul>
                </li>
              </>
            )}
          </ul>
          </div>


        </div>
      </div>
    </>
  );
}



