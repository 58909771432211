import React, { useEffect, useState, useMemo } from "react";
import { api } from "../../components/lib/base";
import Pagination from "react-js-pagination";
import "../../components/customer/pagination.css";
import { ColorRing } from "react-loader-spinner";
import constants from "../constants/constant";
// import CustomerAssign from "./CustomerAssign";
import { toast } from "react-toastify";
import { API_URL } from "../../components/api/api_settings";
// const CustomerAssignModal = (props) => {
//   return <CustomerAssign {...props} />;
// };
function CustomerStatus() {
  const [data, setData] = useState([]);
  const [active, setActive] = useState(true);
  const [active_1, setActive_1] = useState(false);
  const [active_2, setActive_2] = useState(false);
  const [active_3, setActive_3] = useState(true);
  const [active_6, setActive_6] = useState(true);
  const [agent, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [check_all, setcheckall] = useState(false);
  const [check_values, setcheckValues] = useState([]);
  const [saveTrigger, setSaveTrigger] = useState(0);
  const [validStatus, setValidStatus] = useState();
  const [error, setError] = useState({});
  const [count,setCount] = useState(0);           //Total count of data
  const [value, setValue] = useState({
    invite_accpeted: "",
    agent_id: "",
    agent: "",
    excel_file: "",
  });
  const [pages, setpages] = useState({
    CurrentPage: 1,
    lastPages: 0,
    pagination_item: [],
    totalPages: 0,
  });
  const mem = useMemo(() => {
    // ...
  }, [pages.CurrentPage,active,active_1,active_2,active_3,active_6]);

  useEffect(() => {
    getList(pages.CurrentPage);
    getAgentList();
  }, [mem]);
  const getList = (page = 1, status) => {
    setLoading(true);
    console.log(status, "status");
    let b = status ? status : "profile_completion_status=activecall";
    api
      .get(`customer?page=${page}&${b}`, {}, true)
      .then(async function ([success, response]) {
        let tr = response?.data?.data;
        tr?.map((item) => (item.checked = false));
        setCount(response?.data?.total);     
        setData(tr || []);
        setpages({
          ...pages,
          CurrentPage: response?.data?.current_page,
          lastPages: response?.data?.last_page,
          Per_page: response?.data?.per_page,
          totalPages: response?.data?.total,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getAgentList = (page = 1) => {
    setLoading(true);
    api
      .get(`agents`, {}, true)
      .then(async function ([success, response]) {
        let datas = [];
        response?.agent_list?.forEach((elem) => {
          datas.push({ value: elem.id, label: elem.name });
        });
        setAgent(datas);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggleModal() {
      if (isShowing) {
      }
      if (!isShowing) {
      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggleModal,
    };
  };
  const { isShowing, toggleModal } = UseModal();
  const handleUnSelectAll = (e) => {
    setIsCheck(data?.map((item) => (item.checked = false)));
    let checkValues = data?.filter((item) => item?.checked !== false);
    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });
    setcheckValues(tr);
    setcheckall(false);
  };
  const handleSelectAll = (e) => {
    setIsCheck(data?.map((item) => (item.checked = true)));
    let checkValues = data?.filter((item) => item?.checked !== false);
    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });

    setcheckValues(tr);
    setcheckall(true);
  };
  const singleCheck = (e, ids, item, index) => {
    var new_data = [...data];
    new_data[index].checked = !new_data[index]?.checked;
    setData(new_data);
    let checkValues = new_data?.filter((item) => item?.checked !== false);

    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });

    setcheckValues(tr || []);
  };

  const onChange = (option) => {
    setValue({
      ...value,
      agent_id: option?.value,
    });
    setError({
      ...error,
      agent_id: "",
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!value?.agent_id) {
      setError({
        ...error,
        agent_id: "Agent is required",
      });
    } else {
      sendData();
    }
  };
  const [err, setErr] = useState([]);
  const sendData = () => {
    api
      .post(
        `agentassign`,
        {
          user_id: value?.agent_id,
          customer_profile_id: check_values,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success(response?.message);
          toggleModal(!isShowing);
          getList(1);
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function uploadSingleFile(e) {
    setErr([]);
    const formData = new FormData();
    formData.append("excel_file", e.target.files[0]);
    const body = formData;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body,
    };
    fetch(`${API_URL}customersimport`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          toast.success(responseData?.message);
          getList(1);
        } else {
          toast.error("Some Datas Are Already Exists");
        }
        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  }
  return (
    <>
      <div className="right-content-wrap">
        <div className="list-of-customers">
        <div class="topnav">
           
            <a
              class={active_1 == true  ? "nav-link active" : "nav-link" }
              href="#"
              onClick={() => {
                setActive(false);
                setActive_1(true);
                setActive_2(false);
                setActive_3(false);
                getList(pages.current_page, "assigned=1");

              }}
            >
              Assigned
            </a>

            <a
              class={active_2 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={() => {
                setActive(false);
                setActive_1(false);
                setActive_2(true);
                setActive_3(false);
                getList(pages.current_page, "profile_completion_status=inprogress");

              }}
            >
              Progressed
            </a>

            <a
              class={active_6 == true && active_1 == false && active_2 == false? "nav-link active" : "nav-link"}
              href="#"
              onClick={() => {
                setActive(false);
                setActive_1(false);
                setActive_2(false);
                setActive_6(true);
                getList(pages.current_page, "profile_completion_status=activecall");

              }}
            >
              Completed
            </a>

           
          </div>
          <div className="card list-card-head p-3 mt-5">
            <div className="d-flex align-items-center justify-content-between flex-wrap  gap-2">
              <div className="main-title">
                {/* <h5 className="mb-0">List of Customers</h5> */}
                {/* added count in list of customers */}
                <h5 className="mb-0" >List of Customers (<span style={{ color: '#EE307Fed' }}>{count}</span>)</h5>
              </div>
              {/* <div class="head-menu-buttons d-flex align-items-center gap-3 flex-wrap">
                {active == true ? (
                  <>
                    <label className="custom-file-upload px-4">
                      <input
                        type="file"
                        style={{
                          width: "0px",
                          height: "0px",
                          overflow: "hidden",
                        }}
                        name=""
                        value={value?.excel_file}
                        accept=".xls,.XLSX"
                        onChange={uploadSingleFile}
                      />
                      <i
                        class="fa-solid fa-file-import"
                        style={{ color: "#EE307F" }}
                      ></i>{" "}
                      Import Agent List
                    </label>
                    {check_values?.length == 0 ? (
                      <a className="px-4" disabled>
                        <i
                          class="fa-sharp fa-solid fa-plus"
                          style={{ color: "#EE307F" }}
                        ></i>{" "}
                        Assign Agent
                      </a>
                    ) : (
                      <a
                        href="#"
                        className="px-4"
                        onClick={() => toggleModal(true)}
                      >
                        <i
                          class="fa-sharp fa-solid fa-plus"
                          style={{ color: "#EE307F" }}
                        ></i>{" "}
                        Assign Agent
                      </a>
                    )}
                  </>
                ) : null}
              </div> */}
            </div>
            {/* {err?.map((item) => {
              return (
                <>
                  {" "}
                  <span style={{ color: "red" }}>{item?.errors?.[0]}</span>
                </>
              );
            })} */}
          </div>

          {loading ? (
            <ColorRing
              visible={true}
              height="150"
              width="150"
              ariaLabel="blocks-loading"
              wrapperStyle={{ marginLeft: "50%", marginTop: "20%" }}
              wrapperClass="blocks-wrapper"
            />
          ) : (
            <div className="row mt-4 gx-3 gy-3">
              {data?.length > 0 ? (
                <div className="col-lg-12 col-md-12">
                  <div className="table-wrap">
                    <table className="w-100">
                      <thead>
                        <th>Sl.No</th>
                        <th>DWMS_id</th>
                        <th>Job Seeker ID</th>
                        <th>Jobseeker Name</th>
                        <th>Mobile Number</th>
                        <th>Email ID</th>
                        {active_2 == true ? <th>Status</th> : <th></th>}

                        {/* <th>
                          {active == true ? (
                            <>
                              {" "}
                              {check_all == false ? (
                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleSelectAll(e)}
                                  className="sub-btn btn selectall-btn"
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleUnSelectAll(e)}
                                  className="btn sub-btn selectall-btn"
                                />
                              )}
                            </>
                          ) : null}
                        </th> */}
                      </thead>
                      <tbody>
                        {data?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{`${index+1 == 10 ? (pages.CurrentPage != null ? pages.CurrentPage : 1 - 1) * (index+1) : pages.CurrentPage != null ? pages.CurrentPage-1 +""+ (index+1) : null}`}</td>
                                <td>{item?.dwms_profile_id}</td>
                                <td>{item?.job_seeker_id}</td>
                                <td>
                                  <div className="d-flex align-items-center account-image-details">
                                    <img
                                      src="images/accountpic.png"
                                      class="me-2"
                                    />
                                    <div className="name_and_mail">
                                      <h6 className="mb-0">{item?.name}</h6>
                                    </div>
                                  </div>
                                </td>

                                <td>{item?.mobile_no}</td>
                                <td>{item?.email_id}</td>
                                <td style={{ color: "green" }}>
                                  {active_2 == true ? (
                                    <>
                                      {/* {item?.followup_status == 0
                                        ? "Call not initiated"
                                        : null} */}
                                      {item?.followup_status == 0
                                        ? "first Call"
                                        : null}
                                      {item?.followup_status == 1
                                        ? "First Followup"
                                        : null}
                                      {item?.followup_status == 2
                                        ? "Second Followup"
                                        : null}
                                    </>
                                  ) : (
                                    <td></td>
                                  )}
                                </td>
                                {/* <td>
                                  {active == true ? (
                                    <input
                                      type="checkbox"
                                      name="invite_accpeted"
                                      value={value?.invite_accpeted}
                                      onClick={(e) =>
                                        singleCheck(e, item?.id, item, index)
                                      }
                                      checked={item?.checked}
                                    />
                                  ) : null}
                                </td> */}
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div style={{ marginTop: "5%" }}>
                    <Pagination
                      className="paginations"
                      activePage={pages.CurrentPage ? pages.CurrentPage : 0}
                      itemsCountPerPage={pages.Per_page ? pages.Per_page : 0}
                      totalItemsCount={pages.totalPages ? pages.totalPages : 0}
                      onChange={(pageNumber) => {
                        getList(pageNumber,(active_1 == true ? "assigned=1" : active_2 == true ? "profile_completion_status=inprogress" : active_3 == true ? "profile_completion_status=completed": null ));
                      }}
                      pageRangeDisplayed={5}
                      itemClass="ul-li"
                      linkClass="page-link"
                      prevPageText="<"
                      nextPageText=">"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                </div>
              ) : (
                <h3 style={{ marginLeft: "40%" }}>No Records Found</h3>
              )}
            </div>
          )}
        </div>
      </div>
      {/* {isShowing == true ? (
        <>
          <CustomerAssignModal
            isShowing={isShowing}
            hide={toggleModal}
            agent_data={agent}
            onChange={onChange}
            handleSubmit={handleSubmit}
            error={error}
          />
        </>
      ) : null} */}
    </>
  );
}

export default CustomerStatus;
