function convertFieldName(str) {
    var splitStr = str.replaceAll("_", " ").toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0) + splitStr[i].substring(1);
    }
    var text= splitStr.join(" ");
    return text[0].toUpperCase() + text.slice(1);
}
function convertFieldNameLower(str) {
    var splitStr = str.replaceAll("_", " ").toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
        splitStr[i] =
            splitStr[i].charAt(0) + splitStr[i].substring(1);
    }
    var text= splitStr.join(" ");
    return text
}
function FirstLetterUpper(text){
    return text[0].toUpperCase() + text.slice(1);
  }
function validateNumber(strNumber) {
    var regExp = new RegExp("^\\d+$");
    var isValid = regExp.test(strNumber);
    return isValid;
}
function validatePhoneNumber(strNumber) {
    var Exp = new RegExp('/^[1-9]{1}[0-9]{9}$/');
    var isValid = Exp.test(strNumber);
    return isValid;
}
function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}
function validateFloatNumber(number, limit = 2) {
    const re = /^\d*[\.,]\d*(?:,\d*[\.,]\d*)*$/;
    return re.test(number);
}
function smallestToBiggest(a, b) {
    let a_1 = new Date(a.createdAt);
    let b_1 = new Date(b.createdAt);
    return a_1 - b_1;
  }
  function biggestToSmallest(a, b) {
    let a_1 = new Date(a.createdAt);
    let b_1 = new Date(b.createdAt);
    return b_1 - a_1;
  }
function stringToRegex(s, m) {
    return (m = s.match(/^([\/~@;%#'])(.*?)\1([gimsuy]*)$/))
        ? new RegExp(
            m[2],
            m[3]
                .split("")
                .filter((i, p, s) => s.indexOf(i) === p)
                .join("")
        )
        : new RegExp(s);
}
function ProcessData(data, fields = ["id", "name"]) {
    console.log(data)
    let dataProcessedResponse1 = data?.map(function (obj) {
        obj["value"] = obj[fields[0]];
        obj["label"] = obj[fields[1]];
        delete obj[fields[0]];
        delete obj[fields[1]];
        return obj;
    });
    return dataProcessedResponse1;
}
function UpperTOLower(text) {
    let text_o = text.toLowerCase();
    return text_o;
}
function LowerTOUpper(text) {
    let text_o = text.toUpperCase();
    return text_o;
}
function convertISODateToDate(iso_date) {
    var date = new Date(iso_date);
    let months = {
        month_names: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
        month_names_short: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ],
    };
    return (
        months.month_names_short[date.getMonth()] +
        " " +
        date.getDate() +
        " , " +
        date.getFullYear()
    );
}
function validateDatetotime(iso_date) {
    var date = new Date(iso_date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return hours + ":" + minutes + " " + ampm;
}
function convertISODateToDateTime(iso_date_time) {
    return (
        convertISODateToDate(iso_date_time) +
        " " +
        validateDatetotime(iso_date_time)
    );
}
function kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
function dateformat(n){
    const [year, month, day] =  n.split('-')
    return(`${day}/${month}/${year}`)
}
export {
    convertFieldName,
    validateNumber,
    validateEmail,
    validateFloatNumber,
    stringToRegex,
    ProcessData,
    validatePhoneNumber,
    UpperTOLower,
    LowerTOUpper,
    convertISODateToDate,
    convertISODateToDateTime,
    kFormatter,
    FirstLetterUpper,
    convertFieldNameLower,
    dateformat,
    smallestToBiggest,
    biggestToSmallest
};