const getDetails = (props = null) => {
  if (typeof window !== "undefined") {
    let token = props?.user_token || localStorage.getItem("AUTH_TOKEN");
    let user_name = props?.user_name || localStorage.getItem("username");
    let email = props?.email || localStorage.getItem("email");

    let user_details = {
      loggedin: token ? true : false,
      token: token,
      user_name: user_name,
      email: email,
    };
    return user_details;
  }
};

export default getDetails;
