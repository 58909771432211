import React, { useEffect } from "react";
import { useState } from "react";
import { api } from "../../components/lib/base";
import Select, { components } from "react-select";
import { ColorRing } from "react-loader-spinner"; //loader
import { API_URL } from "../../components/api/api_settings";
import { toast } from "react-toastify";
import { FOLLOW_DATA } from "../../components/constants/selection_options";
import axios, { AxiosRequestConfig } from "axios";
import Pagination from "react-js-pagination";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from "date-fns";
import { CSVLink } from "react-csv";
function Reports() {
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [pages, setpages] = useState({
    CurrentPage: 1,
    lastPages: 0,
    pagination_item: [],
    totalPages: 0,
  });
  const [value, setValue] = useState({
    agent: "",
  });
  const [values, setValues] = useState({
    start_date: "",
    end_date: "",
    followtype: "",
  });
  const [loading, setLoading] = useState(false);
  // const onChange = (option) => {
  //   setValue({
  //     ...value,
  //     agent: option?.value,
  //   });
  // };
  const onChange = (option) => {
    setValue({
      ...value,
      agent: option?.value,
    });
  };

  console.log(value, "value");

  const onChangess = (opt) => {
    setValues({
      ...value,
      followtype: opt?.value,
    });
  };
  const onChanges = (e, field_name = null) => {
    setValues({
      ...values,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    getAgentList();
  }, []);
  useEffect(() => {
    if (
      value?.agent === "" &&
      values?.start_date === "" &&
      values?.end_date === "" &&
      values?.followtype === ""
    ) {
      getList();
    }
  }, [value?.agent, values?.start_date, values?.end_date, values?.followtype]);
  const getAgentList = () => {
    api
      .get(`agent`, {}, true)
      .then(async function ([success, response]) {
        let datas = [];
        response?.data?.forEach((elem) => {
          datas.push({ value: elem.id, label: elem.name });
        });
        setData(datas);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const reset = () => {
    setValue({ agent: "" });
    setValues({ start_date: "", end_date: "", followtype: "" });
    handleSubmit();
  };
  const handleSubmit = () => {
    getList();
  };
  const getList = (page = 1) => {
    const originalStartDate = new Date(values?.start_date);
   
    const formattedStartDate = originalStartDate.toLocaleDateString("en-GB");
    // Extract components of the start_date
    const [startDay, startMonth, startYear] = formattedStartDate?.split('/');
    // Create a new formatted date string in "yyyy-mm-dd" format for start_date
    const formattedStartDateInYMD = `${startYear}-${startMonth}-${startDay}`;
  
    const originalEndDate = new Date(values.end_date);
    const formattedEndDate = originalEndDate?.toLocaleDateString("en-GB");
    // Extract components of the end_date
    const [endDay, endMonth, endYear] = formattedEndDate?.split('/');
    // Create a new formatted date string in "yyyy-mm-dd" format for end_date
    const formattedEndDateInYMD = `${endYear}-${endMonth}-${endDay}`;
  
    setLoading(true);
    api
      .post(
        `customer-report?page=${page}`,
        {
          start_date: startYear? formattedStartDateInYMD : '',
        end_date: endYear? formattedEndDateInYMD : '',
        agent: value?.agent,
          type: values?.followtype,
        },
        true
      )
      .then(async function ([success, response]) {
        setDatas(response?.data?.data || []);
        setpages({
          ...pages,
          CurrentPage: response?.data?.current_page,
          lastPages: response?.data?.last_page,
          Per_page: response?.data?.per_page,
          totalPages: response?.data?.total,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  
  const dwonload_file = async () => {
    const headers = {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "blob",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
    };
    const originalStartDate = new Date(values.start_date);
    const formattedStartDate = originalStartDate.toLocaleDateString("en-GB");
    // Extract components of the start_date
    const [startDay, startMonth, startYear] = formattedStartDate.split('/');
    // Create a new formatted date string in "yyyy-mm-dd" format for start_date
    const formattedStartDateInYMD = `${startYear}-${startMonth}-${startDay}`;
  
    const originalEndDate = new Date(values.end_date);
    const formattedEndDate = originalEndDate.toLocaleDateString("en-GB");
    // Extract components of the end_date
    const [endDay, endMonth, endYear] = formattedEndDate.split('/');
    // Create a new formatted date string in "yyyy-mm-dd" format for end_date
    const formattedEndDateInYMD = `${endYear}-${endMonth}-${endDay}`;
  
    const body = {
      start_date: formattedStartDateInYMD,
      end_date: formattedEndDateInYMD,
      agent: value?.agent,
      type: values?.followtype,
    };
    const config = {
      method: "GET",
      url: `${API_URL}customer-report-export?start_date=${formattedStartDateInYMD}&end_date=${formattedEndDateInYMD}&agent=${value?.agent}&type=${values?.followtype}`,
      responseType: "arraybuffer",
      headers,
    };
    try {
      const response = await axios(config);
      const outputFilename = `${Date.now()}.xls`;
      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
      // OR you can save/write file locally.
      // fs.writeFileSync(outputFilename, response.data);
    } catch (error) {
      throw Error(error);
    }
    const requestOptions = {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "blob",
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
    };
  };
  const alert_data = () => {
    toast.info("Select Agent,Start Date or End Date");
  };
  //excel
  var csv_headers = [
    "Sl.No",
    "DWMS Id",
    "Name",
    "Mobile number",
    "Skills",
    "Followup Date",
  ];
  if (values?.followtype == 1) {
    csv_headers = [
      ...csv_headers,
      "Education",
      "Interested in Dwms",
      "Not Interested Reason",
    ];
  } else if (values?.followtype == 2) {
    csv_headers = [
      ...csv_headers,
      "Job Visibility",
      "Interested Career Support",
    ];
  } else if (values?.followtype == 3) {
    csv_headers = [
      ...csv_headers,
      "Specific Services",
      "Availed Career Services",
      "Unavailed Career Services Reasons",
    ];
  }

  function getCurrentDate() {
    const today = new Date();
    return today;
  }
  const onChanges2 = (date, field_name = null) => {
    setValues({
      ...values,
      [field_name ? field_name : "start_date"]: date,
    });
  };
  return (
    <>
      <div className="right-content-wrap">
        <div className="list-of-customers">
          <div className="card list-card-head p-3 mt-5">
            <div className="d-flex align-items-center justify-content-between flex-wrap  gap-2">
              <div className="main-title">
                <h5 className="mb-0">Reports</h5>
              </div>
              <div class="head-menu-buttons d-flex align-items-center gap-3 flex-wrap">
                <Select
                  isSearchable
                  isClearable
                  options={FOLLOW_DATA}
                  placeholder={"Select Followup"}
                  onChange={(opt) => onChangess(opt)}
                  value={
                    FOLLOW_DATA?.filter(
                      (itm) => itm?.value === values?.followtype
                    )?.[0] || null
                  }
                />
                <Select
                  isSearchable
                  isClearable
                  options={data}
                  placeholder={"Select Agent"}
                  onChange={(option) => onChange(option)}
                  styles={{ fontSize: "14px" }}
                  value={
                    data?.filter((itm) => itm?.value === value?.agent)?.[0] ||
                    null
                  }
                />

                {/* <a className="px-4" disabled>
                      <input
                        type={"date"}
                        name="start_date"
                        style={{ overflow: "hidden" }}
                        onChange={onChanges}
                        value={values?.start_date}
                        max={getCurrentDate()} 
                      />
                    </a>
                    <a className="px-4" disabled>
                      <input
                        name="end_date"
                        type={"date"}
                        style={{ overflow: "hidden" }}
                        onChange={onChanges}
                        value={values?.end_date}
                        max={getCurrentDate()} 
                      />
                    </a> */}
                <a>
                  <DatePicker
                    className="picker"
                    name="start_date"
                    selected={values?.start_date}
                    onChange={(date) => onChanges2(date, "start_date")}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="Start Date"
                    maxDate={getCurrentDate()}
                    style={{ borderWidth: 0, textAlign: "center" }}
                  />
                </a>
                <a>
                  <DatePicker
                    className="picker"
                    name="end_date"
                    selected={values?.end_date}
                    onChange={(date) => onChanges2(date, "end_date")}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="End Date"
                    maxDate={getCurrentDate()}
                    style={{ borderWidth: 0, textAlign: "center" }}
                  />
                </a>

                {/* Search Button */}
                {/* <a
                      onClick={() => handleSubmit()}
                      className="px-4 btn-primary"
                      disabled={!values?.followtype || !value?.agent}
                      title="Select Agent, Start Date, End Date"
                      style={{ border: "2px solid green" }}
                    >
                      <span style={{ color: "black", cursor: "pointer" }} >
                        {" "}
                        Search <i className="fa-solid fa-magnifying-glass"></i>
                      </span>
                    </a> */}
                <a
                  onClick={() => {
                    if (values.end_date && !values.start_date) {
                      alert_data();
                    } else {
                      handleSubmit();
                    }
                  }}
                  className="px-4 btn-primary"
                  disabled={!values?.followtype || !value?.agent}
                  // style={{ border: "2px solid green" }}
                >
                  <span style={{ color: "black", cursor: "pointer" }}>
                    {" "}
                    Search <i className="fa-solid fa-magnifying-glass"></i>
                  </span>
                </a>
                {/* Reset Button */}
                <a
                  className="px-4"
                  disabled={!values?.followtype || !value?.agent}
                  title="Reset"
                  onClick={reset}
                  // style={{ border: "2px solid green" }}
                >
                  Reset <i className="fa-solid fa-rotate-left"></i>
                </a>

                {/* Download Button */}
                {datas ? (
  <a className="px-4" onClick={() => dwonload_file()}><i className="fa-solid fa-download"></i></a>
) : (
  <a
    className="px-4"
    disabled={!values?.followtype || !value?.agent}
    onClick={() => alert_data()}
  >
    <i className="fa-solid fa-download"></i>
  </a>
)}

              </div>
            </div>
          </div>
          {loading ? (
            <ColorRing
              visible={true}
              height="150"
              width="150"
              ariaLabel="blocks-loading"
              wrapperStyle={{ marginLeft: "40%", marginTop: "15%" }}
              wrapperClass="blocks-wrapper"
            />
          ) : (
            <div className="row mt-4 gx-3 gy-3">
              <div className="col-lg-12 col-md-12">
                <div className="table-wrap" style={{ overflow: "scroll" }}>
                  <table className="w-100">
                    <thead>
                      <th>Sl.No</th>
                      <th>DWMS Id</th>
                      <th>Jobseeker Name</th>
                      <th>Mobile number</th>
                      <th>Intern Name</th>
                      <th>Skills</th>
                      {values.followtype == 1 ? (
                        <>
                          <th>Followup Date</th>
                          <th>Experience</th>
                          <th>Interested in Dwms</th>
                          {/* <th>Not_Interested_Reason</th> */}
                        </>
                      ) : values.followtype == 2 ? (
                        <>
                          <th>Followup Date</th>
                          <th>Job Visibility</th>
                          <th>Interested Career Support</th>
                        </>
                      ) : values.followtype == 3 ? (
                        <>
                          <th>Followup Date</th>
                          <th>Specific Services</th>
                          <th>Availed Career Services</th>
                          {/* <th>Unavailed Career Services Reasons</th> */}
                        </>
                      ) : (
                        ""
                      )}
                    </thead>
                    <tbody>
                      {datas.length > 0 ? (
                        datas?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                {/* <td>{index + 1}</td> */}
                                <td>{`${
                                  index + 1 == 10
                                    ? (pages.CurrentPage != null
                                        ? pages.CurrentPage
                                        : 1 - 1) *
                                      (index + 1)
                                    : pages.CurrentPage != null
                                    ? pages.CurrentPage - 1 + "" + (index + 1)
                                    : null
                                }`}</td>
                                <td>{item?.dwms_profile_id}</td>
                                <td>{item?.jobseeker_name}</td>
                                <td>{item?.mobile_no}</td>
                                <td>{item?.intern_name}</td>
                                <td>
                                  {item?.key_skills ? item?.key_skills : "-"}
                                </td>
                                {values.followtype == 1 ? (
                                  <>
                                    <td>
                                      {item?.firstcall_followup_date != null
                                        ? item?.firstcall_followup_date
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.experience_details != null
                                        ? item?.experience_details
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.intrested_in_dwms == 1
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    {/* <td>{item?.not_intrested_reason != null ? item?.not_intrested_reason: "-"}</td> */}
                                  </>
                                ) : values.followtype == 2 ? (
                                  <>
                                    <td>
                                      {item?.first_followup_date != null
                                        ? item?.first_followup_date
                                        : "-"}
                                    </td>
                                    {/* <td>{item?.first_followupcall_data[0]?.education != null ? item?.firstcall_data[0]?.education : "-"}</td> */}
                                    <td>
                                      {item?.job_visibility == 1 ? "Yes" : "No"}
                                    </td>
                                    <td>
                                      {item?.intrested_in_career_support == 1
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                  </>
                                ) : values.followtype == 3 ? (
                                  <>
                                    <td>
                                      {item?.second_followup_date != null
                                        ? item?.second_followup_date
                                        : "-"}
                                    </td>
                                    {/* <td>{item?.second_followupcall_data[0]?.education != null ? item?.firstcall_data[0]?.education : "-"}</td> */}
                                    <td>
                                      {item?.specific_services
                                        ? item?.specific_services
                                        : "-"}
                                    </td>
                                    <td>
                                      {item?.availed_career_service == 1
                                        ? "Yes"
                                        : "No"}
                                    </td>
                                    {/* <td>{item?.unavailed_service_reason != null ? item?.unavailed_service_reason : "-"}</td> */}
                                  </>
                                ) : (
                                  ""
                                )}
                              </tr>
                            </>
                          );
                        })
                      ) : (
                        <td colSpan={7} style={{ translate: "50%" }}>
                          {" "}
                          No Records Found{" "}
                        </td>
                      )}
                    </tbody>
                  </table>
                </div>
                {datas.length > 0 ? (
                  <div style={{ marginTop: "5%" }}>
                    <Pagination
                      className="paginations"
                      activePage={pages.CurrentPage ? pages.CurrentPage : 0}
                      itemsCountPerPage={pages.Per_page ? pages.Per_page : 0}
                      totalItemsCount={pages.totalPages ? pages.totalPages : 0}
                      onChange={(pageNumber) => {
                        getList(pageNumber);
                      }}
                      pageRangeDisplayed={5}
                      itemClass="ul-li"
                      linkClass="page-link"
                      prevPageText="<"
                      nextPageText=">"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default Reports;
