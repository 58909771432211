import React, { useEffect, useState } from "react";
import { FaSignOutAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_URL } from "../api/api_settings";
import { convertISODateToDate } from "../converts/Converts";
import Swal from "sweetalert2";
import { getData, clearData } from "../lib/keyStorage";
import getDetails from "../lib/getDetails";

export default function Headers() {
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState();

  useEffect(() => {
    getYear();
  }, []);
  useEffect(() => {
    setName(localStorage.getItem("username"));
    setEmail(localStorage.getItem("email"));
  });

  const getYear = () => {
    var today = new Date();
    const date =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();
    console.log(convertISODateToDate(today), "////");

    setDate(today);
  };
  const Log_out = () => {
    Swal.fire({
      title: "Are you sure you want to logout?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
      }
    });
  };
  const logout = async () => {
    await clearData();
    await getDetails({
      type: "USER_DETAILS",
      isLoggedIn: false,
      token: "",
    });
    navigate("/");
  };
 ;
  return (
    <header class="top-bar">
      <div class="top-bar-full-wrap">
        <nav class="navbar navbar-expand ">
          <div class="container-fluid">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="d-flex align-items-center gap-5">
                <a
                  class="text-decoration-none d-block d-md-none d-lg-none d-xl-none d-xxl-none"
                  data-bs-toggle="offcanvas"
                  href="#mobile-sidebar"
                  role="button"
                  aria-controls="mobile-sidebar"
                >
                  <i class="fa fa-bars"></i>
                </a>
                <a class="navbar-brand" href="#">
                  <img src="images/Oricoms.png" alt="icon" />
                </a>
                <div>
                  {/* <form class="d-flex" role="search">
                    <div class="input-group search-group">
                      <span
                        class="input-group-text bg-transparent border-0 pe-1"
                        id="search"
                      >
                        <i class="fa-sharp fa-solid fa-magnifying-glass"></i>
                      </span>
                      <input
                        type="text"
                        class="form-control border-0"
                        placeholder="Search here"
                        aria-label="Username"
                        aria-describedby="search"
                      />
                    </div>
                  </form> */}
                </div>

               
              </div>
              <div class="d-flex align-items-center gap-4">
              

                <div class="dropdown account-dropdown d-none d-md-block d-lg-block d-xl-block d-xxl-block">
                  <a
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    class="text-decoration-none"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false "
                  >
                    <div class="d-flex align-items-center avatar-dropdown-image">
                      <img src="images/avatar-pic.png" class="me-3" />
                      <div class="name_and_mail">
                        <h6 class="mb-0">
                          {name}
                          <br />
                          <small>{email}</small>
                        </h6>
                      </div>
                    </div>
                  </a>
                  <div
                    class="dropdown-menu p-4"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <div class="d-flex align-items-center avatar-dropdown-image">
                      <img src="images/avatar-pic.png" class="me-3" />
                      <div class="name_and_mail">
                        <h6 class="mb-0">
                          {name}
                          <br />
                          <small>{email}</small>
                        </h6>
                      </div>
                    </div>
                    <hr />
                    <div class="account-tab-list">
                      <a class="dropdown-item" href="#">
                        Action
                      </a>
                      <a class="dropdown-item" href="#">
                        Another action
                      </a>
                      <a class="dropdown-item" href="#">
                        Something else here
                      </a>
                    </div>
                  </div>
                </div>

                <span style={{ cursor: "pointer" }}>
                  <FaSignOutAlt
                    onClick={(e) => {
                      Log_out();
                    }}
                  />{" "}
                </span>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
}
