import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "antd/dist/reset.css";
// import "./style.css";
import ProgressChart from "../../../components/ProgressChart/ProgressChart";
import { api } from "../../../components/lib/base";
import { useNavigate } from "react-router-dom";


function AdminDashboard() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [value, setValue] = useState({
    summary: "",
  });
  const [values, setValues] = useState({
    summary: "",
  });
  const [total, setTotal] = useState([]);

  const onChange = (e, field_name = null) => {
    setValue({
      ...value,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  const onChanges = (e, field_name = null) => {
    setValues({
      ...values,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    getList();
  }, [value?.summary, values?.summary]);

  const date = [

    { id: 0, name: "All" },
    { id: 1, name: "This week" },
    { id: 2, name: "This month" },
    { id: 3, name: "This year" },
  ];
  const getList = () => {
    let v = values?.summary
      ? values?.summary
      : value?.summary
      ? value?.summary
      : 0;
    api
      .get(`dashboard?summary=${v}`, {}, true)
      .then(async function ([success, response]) {
        setData(response?.data);

        let t =
          response?.data?.totafirstcallcount +
          response?.data?.totalfirstfollowupcount +
          response?.data?.secondfollowupcount;
        setTotal(t);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const go_page = () => {
    navigate("/admin_customer_list", { state: { active_6: true } });
  };

  var summary = [];
  summary = [""]
  return (
    <>
      <div class="page-wrapper">
        <div class="row m-0">
            <div class="right-content-wrap">
              <div class="row gy-3 gx-3">
                <div class="col-md-6">
                  <div class="card border-0 custom-shadow h-100">
                    <div class="follow-up-wrap">
                      <div class="card-header bg-white px-2rem py-3">
                        <div class="d-flex align-items-center justify-content-between flex-wrap gap-2">
                          <h6 class="mb-0">Follow up</h6>
                          <small class="d-flex align-items-center">
                            Show :
                            <span className="custom-shadow ms-3">
                              <select
                                name="summary"
                                value={values?.summary}
                                onChange={onChanges}
                                id=""
                                class="form-select border-0 bg-transparent py-0 "
                              >
                                {date?.map((item) => {
                                  return (
                                    <>
                                      <option
                                        value={item?.id}
                                        selected=""
                                        name={item?.name}
                                      >
                                        {item?.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </span>
                          </small>
                        </div>
                      </div>
                      <div class="card-body p-2rem">
                        <div class="follow-up-content">
                          <div class="d-flex align-items-end justify-content-between justify-content-md-center flex-wrap gap-4">
                            <div class="follow-up-image">
                              <img src="images/followup.png" alt="img" />
                            </div>
                            <div class="follow-up-count">
                              <h2>
                              {values?.summary === "1" ? (
                                    data?.totalcompletedcalls_week  || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalcompletedcalls_month  || 0
                                  ) : values?.summary === "3" ? (
                                    data?.totalcompletedcalls_year  || 0
                                  ) : (
                                    data?.totalcompletedcalls  || 0
                                  )}/<span> {values?.summary === "1" ? (
                                    data?.total_call_assigned_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalcall_assigened_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_asigned_calls_year || 0
                                  ) : (
                                    data?.totalassignedcall || 0
                                  )}</span>
                              </h2>

                              <button
                                onClick={() => go_page()}
                                class="btn btn-primary px-2"
                              >
                                {" "}
                                Check Now
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="card border-0 custom-shadow h-100">
                    <div class="follow-up-wrap">
                      <div class="card-header bg-white px-2rem py-3">
                        <h6 class="mb-0">Total Calls</h6>
                      </div>
                      <div class="card-body p-2rem">
                      <ProgressChart data={data} summary={values?.summary} />
                      </div>
                    </div>
                  </div>
                </div>

                {/* start */}
                <div class="col-xl-12">
                  <div class="calls-status-wrap">
                    <div class="row gy-3 gx-3">
                    <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Unassigned Calls</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    12.5%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}> {values?.summary === "1" ? (
                                    data?.total_call_assigned_week1 || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalcall_assigened_month1 || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_asigned_calls_year1 || 0
                                  ) : (
                                    data?.totalassignedcall1 || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Assigned Calls</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    12.5%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}> {values?.summary === "1" ? (
                                    data?.total_call_assigned_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalcall_assigened_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_asigned_calls_year || 0
                                  ) : (
                                    data?.totalassignedcall || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="completed-call-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Active Calls</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/GraphLineBlue.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    {data?.totalcompletedcallpercentage}%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}>{values?.summary === "1" ? (
                                    data?.completed_call_weeks || 0
                                  ) : values?.summary === "2" ? (
                                    data?.completed_call_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.completed_call_year || 0
                                  ) : (
                                    data?.completedcalls || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Inactive</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    {data?.totalcompletedcallpercentage}%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}>   {values?.summary === "1" ? (
                                    data?.total_not_attended_calls_week1 || 0
                                  ) : values?.summary === "2" ? (
                                    data?.total_not_attended_calls_month1 || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_not_attended_calls_year1 || 0
                                  ) : (
                                    data?.total_not_attended_calls1 || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Incomplete Profiles</h6>

                              <div class="d-flex align-items-center gap-3"> */}
                                 {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    {data?.totalincompletedpercentage}%
                                  </small>
                                </div>  */}
                                 {/* <h2 style={{marginLeft:"auto",marginRight:"auto" }}> {values?.summary === "1" ? (
                                    data?.totalincompletedpercentage_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalincompletedpercentage_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.totalincompletedpercentage_year || 0
                                  ) : (
                                    data?.totalincompletedpercentage || 0
                                  )}%</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>  */}
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="non-attended-call-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Archive </h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/GraphLineRed.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/Upred.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    4
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}>{values?.summary === "1" ? (
                                    data?.total_not_attended_calls_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.total_not_attended_calls_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_not_attended_calls_year || 0
                                  ) : (
                                    data?.total_not_attended_calls || 0 
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>Pending Calls</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    12.5%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}> {values?.summary === "1" ? (
                                    data?.totalattendedcalls_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalattendedcalls_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.totalattendedcalls_year || 0
                                  ) : (
                                    data?.totalattendedcalls || 0
                                  )}</h2>
                              </div>
                          </div>
                        </div>
                      </div>
                      </div>
                     
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="completed-call-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>No of Intern </h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/GraphLineBlue.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    +39.69%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}> {values?.summary === "1" ? (
                                    data?.totalagents_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.totalagents_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.totalagents_year || 0
                                  ) : (
                                    data?.totalagents || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-3 col-sm-3">
                        <div class="assigned-calls-wrap card custom-shadow border-0 p-4">
                          <div class="d-flex align-items-center justify-content-center gap-5">
                            <div class="left">
                              <h6>No of Jobseeker</h6>
                              <div class="d-flex align-items-center gap-3">
                                {/* <div class="progress-image">
                                  <img
                                    src="images/LineGraphGreen.svg"
                                    alt="img"
                                  />
                                </div>
                                <div class="calls-count">
                                  <small class="text-success">
                                    <img
                                      src="images/upgreen.svg"
                                      alt="icon"
                                      class="me-2"
                                    />
                                    12.5%
                                  </small>
                                </div> */}
                                <h2 style={{marginLeft:"auto",marginRight:"auto" }}>{values?.summary === "1" ? (
                                    data?.total_customers_week || 0
                                  ) : values?.summary === "2" ? (
                                    data?.total_customers_month || 0
                                  ) : values?.summary === "3" ? (
                                    data?.total_customers_year || 0
                                  ) : (
                                   data?.total_customers || 0
                                  )}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* end */}

                {/* <div class="col-lg-12">
                  <div class="card border-0 custom-shadow h-100">
                    <div class="Helpdesk summary">
                      <div class="card-header bg-white px-2rem">
                        <div class="d-flex align-items-center justify-content-between">
                          <h6>Helpdesk Summary</h6>
                          <small class="d-flex align-items-center">
                            Show:
                            <span>
                              <select
                                name="summary"
                                value={value?.summary}
                                onChange={onChange}
                                id=""
                                class="form-select border-0 bg-transparent py-0"
                              >
                                {date?.map((item) => {
                                  return (
                                    <>
                                      <option
                                        value={item?.id}
                                        selected=""
                                        name={item?.name}
                                      >
                                        {item?.name}
                                      </option>
                                    </>
                                  );
                                })}
                              </select>
                            </span>
                          </small>
                        </div>
                      </div>
                      <div class="card-body px-0 pt-0">
                        <div class="helpdesk-dash-table overflow-auto">
                          <table class="w-100">
                           
                            <tbody>
                              <tr>
                                

                              </tr>
                              <tr>
                                <td>Total Calls Assigned</td>

                                {total_call_assigned_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                                {/* {total_call_assigned_year?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.year}
                                        {item?.count}
                                      </td>
                                    </>
                                  );
                                })} */}
                                {/* {total_call_assigned_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td>Total Calls Connected</td>

                                {total_call_connected_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                                {total_call_connected_year?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.year}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                                {total_call_connected_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                              <tr>
                                <td>First follow up</td> */}

                                {/* {total_call_first_folloup_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name} {item?.count}
                                      </td>
                                    </>
                                  );
                                })} */}
                                {/* {total_call_first_folloup__year?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.year}
                                        {item?.count}
                                      </td>
                                    </>
                                  );
                                })} */}
                                {/* {total_call_first_folloup_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>

                              <tr>
                                <td>Second follow up</td> */}
                                {/* {total_call_second_folloup_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name} {item?.count}
                                      </td>
                                    </>
                                  );
                                })} */}
                                {/* {total_call_second_folloup__year?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <td>
                                          {item?.year}-{item?.count}
                                        </td>
                                      </>
                                    );
                                  }
                                )}
                                {total_call_second_folloup_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>

                              <tr>
                                <td>Third follow up</td>
                                {total_call_third_folloup_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                                {total_call_third_folloup__year?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.year}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                                {total_call_third_folloup_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}- ({item?.count})
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>

                              <tr>
                                <td>Completed Profiles</td>
                                {total_call_completed_folloup_month?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <td>
                                          {item?.month_name}-{item?.count}
                                        </td>
                                      </>
                                    );
                                  }
                                )} */}
                                {/* {total_call_completed_folloup__year?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <td>
                                          {item?.year}
                                          {item?.count}
                                        </td>
                                      </>
                                    );
                                  }
                                )} */}
                                {/* {total_call_completed_folloup_week?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <td>
                                          {item?.dayname}-{item?.count}
                                        </td>
                                      </>
                                    );
                                  }
                                )}
                              </tr>

                              <tr>
                                <td>Not Connected Profiles</td>
                                {total_call_not_c_folloup_month?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.month_name}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })} */}
                                {/* {total_call_completed_folloup__year?.map(
                                  (item) => {
                                    return (
                                      <>
                                        <td>
                                          {item?.year}
                                          {item?.count}
                                        </td>
                                      </>
                                    );
                                  }
                                )} */}
                                {/* {total_call_not_c_folloup_week?.map((item) => {
                                  return (
                                    <>
                                      <td>
                                        {item?.dayname}-{item?.count}
                                      </td>
                                    </>
                                  );
                                })}
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div> */}
                {/* </div> */} 
              </div>
            </div>
        </div>
      </div>       
    </>
  );
}

export default AdminDashboard;