import React from "react";
import { useRef, useState, useEffect, useContext, useMemo } from "react";
import AuthContext from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { api } from "../lib/base";
import constants from "../constants/constant";
import TextField from "../Fields/TextField";
import Swal from "sweetalert2";
import IconField from "../Fields/IconField";
import { SidebarContext } from "../context/ContextState";
function AgentLogin() {
  const {
    sidebarOpen,
    toggleSidebar,
    firstcallcount,
    customercount,
    firstcallfollowupcount,
    secondcallfollowupcount,
    callcount,
    counts,
    closeallstates
  } = useContext(SidebarContext);
  const [saveTrigger, setSaveTrigger] = useState(0);
  const [validStatus, setValidStatus] = useState();
  const navigate = useNavigate();
  const role_id = localStorage.getItem("role_id");
  useEffect(() => {
    if (localStorage.getItem("AUTH_TOKEN") == null) {
      navigate("/");
    } else {
      if (role_id == constants.USER_ROLES.ADMIN) {
        navigate("/admin_dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  }, []);
  const [value, setValue] = useState({
    username: "",
    password: "",
  });
  const validated = useMemo(
    () =>
      validStatus &&
      !Object.keys(validStatus).some((item) => validStatus[item] === false),
    [validStatus]
  );
  const onChange = (e, field_name = null) => {
    setValue({
      ...value,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setSaveTrigger(saveTrigger + 1);
    if (validated) {
      sendLoginData();
    }
  };
  const sendLoginData = () => {
    api
      .post(
        "login",
        { email: value?.email, password: value?.password, mode: "no-cors" },
        false
      )
      .then(async function ([success, response]) {
        if (response?.status == "error") {
          new Swal(response?.message);
        }
        if (response?.status == "success") {
          new Swal("You Have Successfully Logged In").then((result) => {
            if (result.isConfirmed) {
              window.location.reload()
            }
          });
          let token = response?.message?.token;
          localStorage.setItem("AUTH_TOKEN", token);
          localStorage.setItem("username", response?.message?.intern_name);
          localStorage.setItem("role_id", response?.message?.role_id?.name);
          let role_id = response?.message?.role_id?.name;
          if (role_id == constants.USER_ROLES.ADMIN) {
            navigate("/admin_dashboard");
          } else {
            counts()
            navigate("/dashboard");
          }
        }
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <div class="login">
        <div class="row login-main-row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <div class="login-img-wrap">
              <img src="images/login-img.png" alt="login-background" />
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="login-wrap h-100 px-4 px-lg-5 py-5">
              <div class="logo-wrap pb-lg-5 pb-md-5 mb-5">
                <img src="images/Oricoms.png" alt="img" />
              </div>
              <div class="login-card-welcome-title mb-4 ">
                <h4 class="">
                  Sign into
                  <br />
                  <span>Your Account</span>
                </h4>
              </div>
              <div class="login-content">
                <div class="input-group custom-input mb-4">
                  <IconField
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={value?.email}
                    onChange={onChange}
                    saveTrigger={saveTrigger}
                    validStatus={validStatus}
                    setValidStatus={setValidStatus}
                    setValue={setValue}
                    required
                    className="form-control border-end-0"
                    iconsfont={true}
                  />
                </div>
                <div class=" input-group custom-input mb-4">
                  <IconField
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={value?.password}
                    onChange={onChange}
                    saveTrigger={saveTrigger}
                    validStatus={validStatus}
                    setValidStatus={setValidStatus}
                    setValue={setValue}
                    required
                    iconsfont={true}
                    className="form-control border-end-0"
                  />
                </div>
                <div class="d-flex align-items-center justify-content-between mb-4">
                  <div class="form-check remmemberme d-flex gap-2 align-items-center">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      value=""
                      id="remem"
                    />
                    <label class="form-check-label" for="remem">
                      Remember me?
                    </label>
                  </div>
                  <div class="forgot-pass">
                    {/* <small><a href="" class="text-decoration-none">Forgot your password?</a></small> */}
                  </div>
                </div>
                <div class="login-button-div text-center">
                  <input
                    type="submit"
                    value="Sign in"
                    style={{ backgroundColor: "rgb(84, 152, 238)" }}
                    class="form-control primary-btn d-block mx-auto mb-4"
                    name="Login"
                    onClick={handleSubmit}
                  />
                  {/* <small>Don't have an account?&nbsp;<a href="Signup.html">Sign Up</a></small> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AgentLogin;