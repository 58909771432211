import React from "react";
import { useState,useContext,useEffect } from "react";
import "./customer.css";
import { SidebarContext } from "../context/ContextState";
import { closestNode } from "rsuite/esm/List/helper/utils";
import { MdCancel } from 'react-icons/md';

function CustomerBasicDetails({ handleSubmit, data, button_dis ,setButtonDis}) {
  const { sidebarOpen, toggleSidebar, counts,tab,tabopen ,disabled,viewdisable,tri,setTabopen} = useContext(SidebarContext);


  const [value, setValue] = useState({
    call_status: "1",
    
  });

  const onChange = (e, field_name = null) => {
    setValue({
      ...value,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };
  useEffect(()=>{
    //  if(value.call_status == 1){
    //   tab()
    //  }
  },[value.call_status,data])

  const closes = () =>{
    if(tri == false || tri === true){
      
      viewdisable()
      setButtonDis(false)
      setTabopen(false)

    }
    // document.getElementById('details').style.display="none"
    // document.getElementById('disabled').style.opacity ="1"

  }


  return (
    <>
      <div class="col-md-6 details" id="details" style={{marginTop:"0"}}>
        <div class="customer-card-details-wrap">
          <div class="card bg-white border-0">
            <div class="card-header cardwithclose bg-white d-flex  px-2rem py-3">
              <h5 class="mb-0">Jobseeker Basic Details</h5>
              <span onClick={closes}> <MdCancel  style={{fontSize:"25px",marginLeft:"25%", cursor:"pointer" , verticalAlign:'middle'}} /></span>
            </div>
            <div class="card-body px-2rem pt-3 pb-4">
              <div class="customer-details-form">
                <div class="row gy-2">
                  <div class="col-md-12">
                    <label for="">Dwms ID</label>
                    <input
                      type="text"
                      // disabled
                      class="form-control"
                      name="dwms_id"
                      value={data?.dwms_profile_id}
                      disabled={data?.dwms_profile_id ? true : false}

                      
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Jobseeker Name</label>
                    <input
                      type="text"
                      // disabled
                      class="form-control"
                      name="first_name"
                      value={data?.jobseeker_name}
                      disabled={data?.jobseeker_name ? true : false}

                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Email ID</label>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      // disabled
                      value={data?.email_id}
                      disabled={data?.email_id ? true : false}

                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Phone Number</label>
                    <input
                      type="tel"
                      class="form-control"
                      name="tel"
                      // disabled
                      value={data?.mobile_no}
                      disabled={data?.mobile_no ? true : false}

                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Skills Required</label>
                    <input
                      type="text"
                      class="form-control"
                      name="skills"
                      value={data?.key_skills}
                      disabled={data?.key_skills ? true : false}
                      // disabled
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Address</label>
                    <input
                      type="text"
                      class="form-control"
                      name="address"
                      value={data?.kerala_address_line}
                      disabled={data?.kerala_address_line ? true : false}
                      // disabled
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Experience</label>
                    <input
                      type="text"
                      class="form-control"
                      name="experience"
                      // disabled
                      value={data?.experience_details}
                      disabled={data?.experience_details ? true : false}
                    />
                  </div>
                  <div class="col-md-12">
                    <label for="">Call Established Status</label>
                    <select
                      name="call_status"
                      id="call_status"
                      onChange={onChange}
                      class="form-select bg-white"
                      value={value?.call_status}
                    >
                      <option value="0" name="not_attended">Not Attended</option>
                      <option value="1" name="attended">Attended</option>
                      <option value="2" name="wrongnumber"> Wrong number</option>
                      <option value="3" name="switchoff">Switch off</option>
                      <option value="4" name="not_responding">Not Responding</option>
                      <option value="5" name="unavailable">Number not in use</option>
                      <option value="6" name="GOTJOB">Got Job</option>
                      <option value="7" name="NOTINTRESTEDNOW">Not Interested Now</option>
                      <option value="8" name="NOTUPDATINGPROFILEEVENAFTERFOLLOWUP">Not Updating Profile Even After Followup</option>
                      {/* <option value="9" name="INVALIDCREDENTIALS">Invalid Credentials</option> */}
                      <option value="10" name="NOTRESPONDINGMULTIPLECALLS">Not Responding Multiple Calls</option>
                      <option value="11" name="NOTRESPONDINGMULTIPLECALLS">No Phone Number</option>
                      <option value="12" name="NOTRESPONDINGMULTIPLECALLS">Not Reachable</option>
                      
                    </select>
                  </div>
                  {button_dis == true ? (
                    <div class="col-md-12 text-center mt-4">
                       <button
                         class="btn btn-primary"
                          onClick={(e) =>(
                            handleSubmit({
                              data:data,
                              id: data?.id,
                              ids: data.id,
                              call_status: value?.call_status,
                              followup_status: data?.followup_status,
                              pass_data:""
                            }),value.call_status == 1 ? tab() : null)
                            
                          }
                         disabled  
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div class="col-md-12 text-center mt-4">
                   
                      <a
                        class="btn btn-primary"
                        onClick={(e) =>(
                          handleSubmit({
                            id: data?.id,
                            ids: data?.id,
                            call_status: value?.call_status ? value?.call_status: "",
                            followup_status: data?.followup_status,
                            pass_data:""
                          }),value.call_status == 1 ? tab() : null)
                        }
                      
                      >
                        Save
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerBasicDetails;
