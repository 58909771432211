import axios from "axios";
import { API_URL } from "../api/api_settings";
const urlRegex = new RegExp("^https?:\\/\\/\\S+$");
const isUrl = (str) => urlRegex.test(str);

class Api {
  constructor() {
    this.url = API_URL;
  }

  async get(path, auth = false, headers = {}, timeout = 10000) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);
    var success = false;
    const response = await fetch(this.getApiUrl(path), {
      method: "GET",
      mode: "cors",
      signal: controller.signal, 
      referrerPolicy: "origin",
      "Access-Control-Allow-Origin": "*",
      headers: {
        Accept: "application/json",
        ...(auth
          ? { Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") }
          : // ? { Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6OCwiaWF0IjoxNjczMjQwMjE1LCJleHAiOjE2ODEwMTYyMTV9.9mNF6SHMgYtMBlyxawLmLDFN6JfcRtEZ3hPTPKZQMFM" }
            null),
        ...headers,
      },
    });

    if (response.ok) {
      success = true;
      let data = await response.json();
      return [true, data];
    } else {
      return [success, null];
    }
  }

  async post(path, body = {}, auth = false, headers = {}, timeout = 10000) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);
    var success = false;

    const response = await fetch(this.getApiUrl(path), {
      method: "POST",
      headers: {
        mode: "cors",
        signal: controller.signal,
        referrerPolicy: "origin",
        "Content-Type": "application/json",
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        ...(auth
          ? { Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") }
          : null),
        "X-Requested-With": "XMLHttpRequest",
        ...headers,
      },
      body: JSON.stringify(body),
    });

    if (response) {
      success = true;
      let data = await response.json();
      return [true, data];
    } else {
      return [success, null];
    }
  }

  async delete(path, body = {}, auth = false, headers = {}, timeout = 10000) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);
    var success = false;
    const response = await fetch(this.getApiUrl(path), {
      method: "DELETE",
      mode: "cors",
      signal: controller.signal,
      referrerPolicy: "origin",
      'My-Custom-Header': 'foobar',
      "Access-Control-Allow-Origin": "*",
      headers: {
        Accept: "application/json",
        ...(auth
          ? { Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") }
          : null),
        ...headers,
      },
    });

    if (response.ok) {
      success = true;
      let data = await response.json();
      return [true, data];
    } else {
      return [success, null];
    }
  }

  async file(path, body = null, auth = false, headers = null, timeout = 10000) {
    const controller = new AbortController();
    setTimeout(() => controller.abort(), timeout);

    const formData = new FormData();
    formData.append("file", body);

    let result = await axios
      .post(this.getApiUrl(path), formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          ...(auth
            ? { Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") }
            : null),
          Accept: "application/json",
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    return result;
  }

  getApiUrl(path) {
    return isUrl(path) ? path : this.url + path;
  }
}

export const api = new Api();
