const getData = ({ props = null }) => {
  if (typeof window !== "undefined") {
    let token = localStorage.getItem("AUTH_TOKEN");
    return token;
  }
};
const clearData = () => {
  if (typeof window !== "undefined") {
    localStorage.removeItem("AUTH_TOKEN");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
  }
};
export { getData, clearData };
