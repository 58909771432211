import React, { useEffect, useState, useMemo } from "react";
import { api } from "../../../components/lib/base";
import Pagination from "react-js-pagination";
import "../../../components/customer/pagination.css";
import { ColorRing } from "react-loader-spinner";
import constants from "../../../components/constants/constant";
import CustomerAssign from "./CustomerAssign";
import { toast } from "react-toastify";
import { API_URL } from "../../../components/api/api_settings";
import { useLocation } from "react-router";
import { Input } from "reactstrap";
const CustomerAssignModal = (props) => {
  return <CustomerAssign {...props} />;
};
function CustomerList() {
  const location = useLocation();
  const [keyword,setKeyword] = useState("");      //search keyword
  const [count,setCount] = useState(0);           //Total count of data
  const [trLength,setTrLength]= useState(0);      //count of data on single page
  const [tabStatus,setTabStatus] = useState("");  //track the status of tabs
  const [data, setData] = useState([]);
  const [sl, setSl] = useState(0)
  const [active, setActive] = useState(location?.state == null ? true : false);
  const [active_1, setActive_1] = useState(false);
  const [active_2, setActive_2] = useState(false);
  const [active_3, setActive_3] = useState(false);
  const [active_4, setActive_4] = useState(false);
  const [active_5, setActive_5] = useState(false);
  const [active_6, setActive_6] = useState(location?.state != null ? true : false);
  const [agent, setAgent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [check_all, setcheckall] = useState(false);
  const [check_values, setcheckValues] = useState([]);
  const [saveTrigger, setSaveTrigger] = useState(0);
  const [validStatus, setValidStatus] = useState();
  const [error, setError] = useState({});
  const [disable,setDisable]= useState(true)
  const [buttonDisable,setButtonDisable]= useState(false)
  const [value, setValue] = useState({
    invite_accpeted: "",
    agent_id: "",
    agent: "",
    excel_file: "",
  });
  const [pages, setpages] = useState({
    CurrentPage: 1,
    lastPages: 0,
    pagination_item: [],
    Per_page: 0,
    totalPages: 0,
    total_item: 0
  });
  console.log(pages);
  const fun1 = async () => {
    setActive(true);
    setActive_1(false);
    setActive_2(false);
    setActive_3(false);
    setActive_4(false);
    setActive_5(false);
    setActive_6(false);
    getList(1, "assigned=0");
  }

  const fun2 = async () => {
    setActive_1(true);
    setActive(false);
    setActive_2(false);
    setActive_3(false);
    setActive_4(false);
    setActive_5(false);
    setActive_6(false);
    getList(1, "assigned=1");

  }

  const fun3 = async () => {
    setActive(false);
    setActive_1(false);
    setActive_2(true);
    setActive_3(false);
    setActive_4(false);
    setActive_5(false);
    setActive_6(false);
    getList(1, "profile_completion_status=inprogress");

  }

  const fun4 = async () => {
    setActive(false);
    setActive_1(false);
    setActive_2(false);
    setActive_3(true);
    setActive_4(false);
    setActive_5(false);
    setActive_6(false);
    getList(1, "profile_completion_status=completed");

  }
  // Author : Ananthu A S
  // description: added function for inactive and archived
  const fun5 = async () => {
    setActive(false);
    setActive_1(false);
    setActive_2(false);
    setActive_3(false);
    setActive_4(true);
    setActive_5(false);
    setActive_6(false);
    getList(1, "profile_completion_status=inactive");
  }

  const fun6 = async () => {
    setActive(false);
    setActive_1(false);
    setActive_2(false);
    setActive_3(false);
    setActive_4(false);
    setActive_5(true);
    setActive_6(false);
    getList(1, "profile_completion_status=archived");
  }
  const fun7 = async () => {
    setActive(false);
    setActive_1(false);
    setActive_2(false);
    setActive_3(false);
    setActive_4(false);
    setActive_5(false);
    setActive_6(true);
    
    getList(1, "profile_completion_status=activecall");
  }



  const mem = useMemo(() => {
    // ...
  }, []);

  useEffect(() => {

    if (active == true) {

      getList(1, "assigned=0");
    }
    else if (active_1 == true) {

      getList(1, "assigned=1");
    }
    else if (active_2 == true) {

      getList(1, "profile_completion_status=inprogress");
    }
    else if (active_3 == true) {
      getList(1, "profile_completion_status=completed");
    }
    else if (active_6 == true) {
      getList(1, "profile_completion_status=activecall");
    }

    getList(1);


    getAgentList();

   
  }, [mem,disable]);


  const getList = (page = 1, status) => {
    setLoading(true);

    let b =
      location?.state?.active_6 == true
        ? "profile_completion_status=activecall"
        : status
          ? status
          : "assigned=0";
    api
      .get(`customer?page=${page}&${b}&search_keyword=${keyword}`, {}, true)    //search_keyword
      .then(async function ([success, response]) {
        let tr = response?.data?.data;
        tr?.map(async (item) => (item.checked = false));
        setTrLength(tr.length);                       //count of data on single page
        setCount(response?.data?.total);              //count of total data 
        setpages({
          ...pages,
          CurrentPage: response?.data?.current_page,
          lastPages: response?.data?.last_page,
          Per_page: response?.data?.per_page,
          totalPages: response?.data?.total,
          total_item: response?.data?.total
        });
        await setData(tr || []);
        location.state.active_6 = false

        setLoading(false);
      })

      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  const getAgentList = (page = 1) => {
    setLoading(true);
    api
      .get(`agents`, {}, true)
      .then(async function ([success, response]) {
        let datas = [];
        response?.agent_list?.forEach((elem) => {
          datas.push({ value: elem.id, label: elem.name });
        });
        setAgent(datas);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggleModal() {
      if (isShowing) {
      }
      if (!isShowing) {
      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggleModal,
    };
  };
  const { isShowing, toggleModal } = UseModal();
  const handleUnSelectAll = (e) => {
    setIsCheck(data?.map((item) => (item.checked = false)));
    let checkValues = data?.filter((item) => item?.checked !== false);
    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });
    setcheckValues(tr);
    setcheckall(false);
  };

  const handleSelectAll = (e) => {
    setIsCheck(data?.map((item) => (item.checked = true)));
    let checkValues = data?.filter((item) => item?.checked !== false);
    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });

    setcheckValues(tr);
    setcheckall(true);
  };
  const singleCheck = (e, ids, item, index) => {
    var new_data = [...data];
    new_data[index].checked = !new_data[index]?.checked;
    setData(new_data);
    let checkValues = new_data?.filter((item) => item?.checked !== false);

    let tr = [];
    checkValues?.map((item) => {
      tr.push(item?.id);
    });

    setcheckValues(tr || []);
  };

  const onChange = (option) => {
    setValue({
      ...value,
      agent_id: option?.value,
    });
    setError({
      ...error,
      agent_id: "",
    });
  };
  const handleSubmit = (e) => {
    // e.preventDefault();
    if (!value?.agent_id) {
      setError({
        ...error,
        agent_id: "Agent is required",
      });
    } else {
      sendData();
    }
  };
  const [err, setErr] = useState([]);
  const sendData = () => {
    api
      .post(
        `agentassign`,
        {
          user_id: value?.agent_id,
          customer_profile_id: check_values,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success(response?.message);
          toggleModal(!isShowing);
          getList(1);
          setcheckValues([])
          setButtonDisable(false)

        } else {
          setButtonDisable(false)

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function uploadSingleFile(e) {
    setErr([]);
    const formData = new FormData();
    formData.append("excel_file", e.target.files[0]);
    const body = formData;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      },
      body,
    };
    fetch(`${API_URL}customersimport`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        if (responseData?.status == "success") {
          toast.success(responseData?.message);
          getList(1);
        } else {
          toast.error("Some Datas Are Already Exists");
        }
        return responseData;
      })
      .catch((e) => {
        console.log(e, ">>>");
      });
  }

  console.log(value, "vvvvaaalll")

  const unassign = () => {

    api
      .post(
        `unassign`,
        {
          cust_id: check_values,
          // customer_profile_id: check_values,
        },
        true
      )
      .then(async function ([success, response]) {
        if (response?.status == "success") {
          toast.success(response?.message);
          // toggleModal(!isShowing);
          getList(1, "assigned=1");
        } else {
        }
      })
      .catch((err) => {
        console.log(err);
      });

  }
  // useEffect for searching in customer list
  useEffect(()=>{
    if(keyword != ""){
      if (active == true) {

        getList(1, "assigned=0");
        setTabStatus("assigned=0")
      }
      else if (active_1 == true) {
  
        getList(1, "assigned=1");
        setTabStatus("assigned=1")
      }
      else if (active_2 == true) {
  
        getList(1, "profile_completion_status=inprogress");
        setTabStatus("profile_completion_status=inprogress")
      }
      else if (active_3 == true) {
  
        getList(1, "profile_completion_status=completed");
        setTabStatus("profile_completion_status=completed")
      }
      else if(active_4 == true){
        getList(1, "profile_completion_status=inactive");
        setTabStatus("profile_completion_status=inactive")
      }
      else if(active_5 == true){
        getList(1, "profile_completion_status=archived");
        setTabStatus("profile_completion_status=archived")
      }
      else if(active_6 == true){
        getList(1, "profile_completion_status=activecall");
        setTabStatus("profile_completion_status=activecall")
      }
    } else{
      getList(1, tabStatus);
    }  
  },[keyword])

  //function to add searching value
  const searchData = (e) =>{
    setKeyword(e.target.value)
  }

  return (
    <>
      <div className="right-content-wrap" >
        <div className="list-of-customers">
          <div class="topnav">
            <a
              class={active == true ? "nav-link active" : "nav-link"}
              aria-current="page"
              href="#"
              onClick={async () => {

                fun1()
              }}
            >
              Unassigned
            </a>

            <a
              class={active_1 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {

                await fun2()
              }}
            >
              Assigned
            </a>

            <a
              class={active_2 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {

                await fun3()
              }}
            >
              Progressed
            </a>

            <a
              class={active_3 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {
                await fun4()
              }}
            >
              Active
            </a>
            {/* 
            Author : Ananthu A S
            description : add inactive and archived user */}
            <a
              class={active_4 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {
                await fun5()
              }}
            >
              Inactive
            </a>
            <a
              class={active_5 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {
                await fun6()
              }}
            >
              Archived
            </a>
            <a
              class={active_6 == true ? "nav-link active" : "nav-link"}
              href="#"
              onClick={async () => {
                await fun7()
              }}
            >
              Completed
            </a>
          </div>
          <div className="card list-card-head p-3 mt-5">
            <div className="d-flex align-items-center justify-content-between flex-wrap  gap-2">
              <div className="main-title">
                {/* <h5 className="mb-0">List of Customers</h5> */}
                 {/* added count in list of customers */}
                 <h5 className="mb-0" >List of Jobseekers (<span style={{ color: '#EE307Fed' }}>{count}</span>)</h5>
              </div>
              <div class="head-menu-buttons d-flex align-items-center gap-3 flex-wrap">
                {active == true ? (
                  <>
                    <label className="custom-file-upload px-4">
                      <input
                        type="file"
                        style={{
                          width: "0px",
                          height: "0px",
                          overflow: "hidden",
                        }}
                        name=""
                        value={value?.excel_file}
                        accept=".xls,.XLSX"
                        onChange={uploadSingleFile}
                      />
                      <i
                        class="fa-solid fa-file-import"
                        style={{ color: "#EE307F" }}
                      ></i>{" "}
                      Import Jobseekers List
                    </label>
                    {check_values?.length == 0 ? (
                      <a className="px-4" disabled>
                        <i
                          class="fa-sharp fa-solid fa-plus"
                          style={{ color: "#EE307F" }}
                        ></i>{" "}
                        Assign Interns
                      </a>
                    ) : (
                      <a
                        href="#"
                        className="px-4"
                        onClick={() => toggleModal(true)}
                      >
                        <i
                          class="fa-sharp fa-solid fa-plus"
                          style={{ color: "#EE307F" }}
                        ></i>{" "}
                        Assign Interns
                      </a>
                    )}
                  </>
                ) : null}


                {/* {active_1 == true ? (
                  <>

                    <a
                      href="#"
                      className="px-4"
                      onClick={() => unassign()}
                    >
                      <i
                        class="fa-sharp fa-solid fa-minus"
                        style={{ color: "#EE307F" }}
                      ></i>{" "}
                      Unassign Agent
                    </a>
                  </>
                ) : null} */}
                {!active && (
  <div className="px-4">
    <Input 
      onChange={searchData} 
      placeholder="Search"
      size="sm" 
      name="search"                      
    />
  </div>
)}
                
              </div>
              
            </div>
            {/* {err?.map((item) => {
              return (
                <>
                  {" "}
                  <span style={{ color: "red" }}>{item?.errors?.[0]}</span>
                </>
              );
            })} */}
          </div>

          {loading ? (
            <ColorRing
              visible={true}
              height="150"
              width="150"
              ariaLabel="blocks-loading"
              wrapperStyle={{ marginLeft: "40%", marginTop: "15%" }}
              wrapperClass="blocks-wrapper"
            />
          ) : (
            <div className="row mt-4 gx-3 gy-3">
              {data?.length > 0 ? (
                <div className="col-lg-12 col-md-12">
                  <div className="table-wrap">
                    <table className="w-100">
                      <thead>
                        <th>Sl.No</th>
                        <th>DWMS ID</th>
                        <th>Job Seeker ID</th>
                        <th>Jobseeker Name</th>
                        {active != true ? <th>Assigned Intern</th> : null}
                        <th>Mobile Number</th>
                        <th>Email ID</th>
                        {active_2 == true ? <th>Status</th> : <th></th>}
                        {active_3 == true ? <th>Completed Date</th> : null}

                        <th>
                          {active == true ? (
                            <>
                              {" "}
                              {check_all == false ? (

                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleSelectAll(e)}
                                  className="sub-btn btn selectall-btn"
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleUnSelectAll(e)}
                                  className="btn sub-btn selectall-btn"
                                />
                              )}
                            </>
                          ) : null}

                          {/* {active_1 == true ? (
                            <>
                              {" "}
                              {check_all == false ? (
                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleSelectAll(e)}
                                  className="sub-btn btn selectall-btn"
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  name="selectAll"
                                  id="selectAll"
                                  onClick={(e) => handleUnSelectAll(e)}
                                  className="btn sub-btn selectall-btn"
                                />
                              )}
                            </>
                          ) : null} */}
                        </th>
                      </thead>
                      <tbody>
                        {data?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{`${index+1 == 10 ? (pages.CurrentPage != null ? pages.CurrentPage : 1 - 1) * (index+1) : pages.CurrentPage != null ? pages.CurrentPage-1 +""+ (index+1) : null}`}</td>
                                <td>{item?.dwms_profile_id}</td>
                                <td>{item?.job_seeker_id}</td>
                                <td>
                                  <div className="d-flex align-items-center account-image-details">
                                    <img
                                      src="images/userimage.png"
                                      class="me-2"
                                    />
                                    <div className="name_and_mail">
                                      <h6 className="mb-0">{item?.jobseeker_name}</h6>
                                    </div>
                                  </div>
                                </td>
                                {active != true ? <td>{item?.customer_agent?.agent_user?.name ? item?.customer_agent?.agent_user?.name : "-"}</td> : null}

                                <td>{item?.mobile_no}</td>
                                <td>{item?.email_id}</td>
                                <td >
                                  {active_2 == true ? (
                                    <>
                                      {/* {item?.followup_status == 0
                                        ? "Call not initiated"
                                        : null} */}
                                      {item?.followup_status == 0
                                        ? "First Call Pending"
                                        : null}
                                      {item?.followup_status == 1
                                        ? "First Follow up Pending"
                                        : null}
                                      {item?.followup_status == 2
                                        ? "Second Follow up Pending"
                                        : null}
                                    </>
                                  ) : (
                                    <td></td>
                                  )}
                                </td>
                                {active_3 == true ? <td>{item?.second_followup_date ? item?.second_followup_date : "-" }</td> : null}

                                <td>
                                  {active == true ? (
                                    <input
                                      type="checkbox"
                                      name="invite_accpeted"
                                      value={value?.invite_accpeted}
                                      onClick={(e) =>
                                        singleCheck(e, item?.id, item, index)
                                      }
                                      checked={item?.checked}
                                    />
                                  ) : null}

                                  {/* {active_1 === true ? (
                                    item.followup_status == 0 ?
                                      <input
                                        type="checkbox"
                                        name="invite_accpeted"
                                        value={value?.invite_accpeted}
                                        onClick={(e) =>
                                          singleCheck(e, item?.id, item, index)
                                        }
                                        checked={item?.checked}
                                      />
                                      : null
                                  ) : null} */}
                                </td>

                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div style={{ marginTop: "5%" }}>
                    <Pagination
                      className="paginations"
                      activePage={pages.CurrentPage ? pages.CurrentPage : 0}
                      itemsCountPerPage={pages.Per_page ? pages.Per_page : 0}
                      totalItemsCount={pages.total_item ? pages.total_item : 0}
                      onChange={(pageNumber) => {
            
                        getList(pageNumber,
                          (active == true ? 
                          "assigned=0" : 
                          active_1 == true ?
                           "assigned=1" : 
                           active_2 == true ? 
                           "profile_completion_status=inprogress" 
                           :active_6 == true ? 
                           "profile_completion_status=activecall" 
                           :active_4 == true ? 
                           "profile_completion_status=inactive" 
                           :active_5 == true ? 
                           "profile_completion_status=archived" 
                           :active_3 == true ?
                          "profile_completion_status=completed" 
                           : null))
                        setcheckall(false);   //change state to resolve uncheck issue
                      }}
                      pageRangeDisplayed={5}
                      itemClass="ul-li"
                      linkClass="page-link"
                      prevPageText="<"
                      nextPageText=">"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                </div>
              ) : (
                <h3 style={{ marginLeft: "35%" }}>No Records Found</h3>
              )}
            </div>
          )}
        </div>
      </div>
      {isShowing == true ? (
        <>
          <CustomerAssignModal
            isShowing={isShowing}
            hide={toggleModal}
            agent_data={agent}
            onChange={onChange}
            handleSubmit={handleSubmit}
            error={error}
            setButtonDisable={setButtonDisable}
            buttonDisable ={buttonDisable}
          />
        </>
      ) : null}
    </>
  );
}

export default CustomerList;
