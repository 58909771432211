import React, { useEffect, useState, useMemo } from "react";
import { api } from "../../../components/lib/base";
import Pagination from "react-js-pagination";
import "../../../components/customer/pagination.css";
import { Blocks, ColorRing, InfinitySpin } from "react-loader-spinner";
import AddAgentModal from "./AddAgentModal";
import { USER_DATA } from "../../../components/constants/selection_options";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { API_URL } from "../../../components/api/api_settings";
import EditAgentModal from "./EditAgent";
import ChangePasswordModal from "./ChangepasswordModal";
import { MdCancel } from 'react-icons/md';

import {
  biggestToSmallest,
  smallestToBiggest,
} from "../../../components/converts/Converts";
const AddAgentModals = (props) => {
  return <AddAgentModal {...props} />;
};
const EditAgentModals = (props) => {
  return <EditAgentModal {...props} />;
};

const ChangePasswordModals = (props) => {
  return <ChangePasswordModal {...props} />;
};

function AgentList() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saveTrigger, setSaveTrigger] = useState(0);
  const [validStatus, setValidStatus] = useState();
  const [saveTriggers, setSaveTriggers] = useState(0);
  const [validStatuss, setValidStatuss] = useState();
  const [saveTriggerspass, setSaveTriggerspass] = useState(0);
  const [validStatuspass, setValidStatuspass] = useState();
  const [valid, setValid] = useState(false);
  const [errors, setError] = useState({});
  const [currentpage, setCurrentpage] = useState()
  const validated = useMemo(
    () =>
      validStatus &&
      !Object.keys(validStatus).some((item) => validStatus[item] === false),
    [validStatus]
  );
  const validateds = useMemo(
    () =>
      validStatuss &&
      !Object.keys(validStatuss).some((item) => validStatuss[item] === false),
    [validStatuss]
  );
  const validatedpass = useMemo(
    () =>
      validStatuspass &&
      !Object.keys(validStatuspass).some(
        (item) => validStatuspass[item] === false
      ),
    [validStatuspass]
  );
  const [value, setValue] = useState({
    intern_name: "",
    agent_name: "",
    mobile_number: "",
    email: "",
    password: "",
    password_confirmation: "",
    role_id: "",
  });
  const [values, setValues] = useState({
    intern_name: "",
    agent_name: "",
    mobile_number: "",
    email: "",
    role_id: "",
  });
  const [password, setPassword] = useState({
    password: "",
    password_confirmation: "",
  });

  const [pages, setpages] = useState({
    CurrentPage: 1,
    lastPages: 0,
    pagination_item: [],
    totalPages: 0,
  });

  useEffect(() => {
    getList(pages.CurrentPage);
  }, [pages.CurrentPage]);
  const getList = (page = 1) => {
    setLoading(true);
    api
      .get(`agent?page=${page}`, {}, true)
      .then(async function ([success, response]) {
        let data_s = response?.data;
        setData(data_s?.sort(smallestToBiggest) || []);
        setpages({
          ...pages,
          CurrentPage: response?.meta?.current_page,
          lastPages: response?.meta?.last_page,
          Per_page: response?.meta?.per_page,
          totalPages: response?.meta?.total,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
    console.log(page, "page nnnnn")
  };
  const UseModal = () => {
    const [isShowing, setIsShowing] = useState(false);

    function toggleModal() {
      if (isShowing) {
      }
      if (!isShowing) {
        setValue({
          intern_name: "",
          agent_name: "",
          mobile_number: "",
          email: "",
          password: "",
          password_confirmation: "",
          role_id: "",
        });
        setValues({
          intern_name: "",
          agent_name: "",
          mobile_number: "",
          email: "",
          role_id: "",
        });
        setError({
          ...errors,
          intern_name: "",
          email: "",
          phone_no: "",
        });
      }
      setIsShowing(!isShowing);
    }

    return {
      isShowing,
      toggleModal,
    };
  };
  const { isShowing, toggleModal } = UseModal();
  const UseModals = () => {
    const [isShowings, setIsShowings] = useState(false);

    function toggleModals() {
      if (isShowings) {
      }
      if (!isShowings) {
      }
      setIsShowings(!isShowings);
    }

    return {
      isShowings,
      toggleModals,
    };
  };
  const { isShowings, toggleModals } = UseModals();
  const UseModalPassword = () => {
    const [isShowingpass, setIsShowingpass] = useState(false);

    function toggleModalPass() {
      if (isShowingpass) {
      }
      if (!isShowingpass) {
      }
      setIsShowingpass(!isShowingpass);
    }

    return {
      isShowingpass,
      toggleModalPass,
    };
  };
  const { isShowingpass, toggleModalPass } = UseModalPassword();

  const onChange = (e, field_name = null) => {
    setValue({
      ...value,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
    setValid(false);

    // console.log(field_name, "field_name");
    if (e.target.name == "intern_name") {
      setError({
        ...errors,
        intern_name: "",
      });
    }
    if (e.target.name == "email") {
      setError({
        ...errors,
        email: "",
      });
    }
    if (e.target.name === "mobile_number") {


      setError({
        ...errors,
        phone_no: "",
      })
    }
  };
  const onChangeEdit = (e, field_name = null) => {
    setValues({
      ...values,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
    setValid(false);
    // console.log(field_name, "field_name");
    if (e.target.name == "intern_name") {
      setError({
        ...errors,
        intern_name: "",
      });
    }
    if (e.target.name == "email") {
      setError({
        ...errors,
        email: "",
      });
    }
    if (e.target.name === "mobile_number") {
      setError({
        ...errors,
        phone_no: "",
      })
    }
  };

  const onChangePassword = (e, field_name = null) => {
    setPassword({
      ...password,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
    setValid(false);
  };
  const handleSubmit = (e, edit_id, id) => {
    console.log(edit_id, id, "edit_id, id")
    e.preventDefault();
    setSaveTrigger(saveTrigger + 1);
    if (validated) {
      sendData(edit_id, id);

    }
    // else{
    //   toast.error('Email or Phone Number is already has been taken')
    // }
  };
  const handleSubmitEdit = (e, edit_id, id) => {
    e.preventDefault();
    setSaveTriggers(saveTriggers + 1);
    console.log(id, "edit_id");
    if (validateds && !errors.phone_no) {
      sendData(edit_id, id);
    }
  };
  const handleChangePassword = (e) => {
    e.preventDefault();
    setSaveTriggerspass(saveTriggerspass + 1);

    if (validatedpass) {
      sendPasswordData();
    }
  };

  const sendData = (edit_id, id) => {
    if (edit_id == 1) {
      fetch(`${API_URL}agent/${id}`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        },
        method: "PATCH",

        // Fields that to be updated are passed
        body: JSON.stringify({
          name: values?.agent_name,
          phone_no: values?.mobile_number,
          email: values?.email,
          role_id: values?.role_id,
          intern_name: values?.intern_name,
        }),
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (data) {
          if (data?.status == "success") {
            toast.success(data?.message);
            toggleModals(!isShowings);
            getList(pages?.CurrentPage);
            setValue({
              intern_name: "",
              agent_name: "",
              mobile_number: "",
              email: "",
              role_id: "",
            });
          } else {
            let v1 = data?.errors?.intern_name?.[0];
            let v2 = data?.errors?.email?.[0];
            let v3 = data?.errors?.phone_no?.[0];
            setError({
              ...errors,
              intern_name: v1,
              email: v2,
              phone_no: v3,
            });
          }
        });
    } else {
      if (value?.password !== value?.password_confirmation) {
        setValid(true);
      } else {
        setLoading(true)
        api
          .post(
            `agent`,
            {
              name: value?.agent_name,
              phone_no: value?.mobile_number,
              email: value?.email,
              password: value?.password,
              password_confirmation: value?.password_confirmation,
              role_id: value?.role_id,
              intern_name: value?.intern_name,
            },
            true
          )
          .then(async function ([success, response]) {
            if (response?.status == "success") {
              setLoading(false)
              toast.success(response?.message);
              toggleModal(!isShowing);
              getList(1);
              setValue({
                intern_name: "",
                agent_name: "",
                mobile_number: "",
                email: "",
                password: "",
                password_confirmation: "",
                role_id: "",
              });
            } else {
              setLoading(false)
              let v1 = response?.errors?.intern_name?.[0];
              let v2 = response?.errors?.email?.[0];
              let v3 = response?.errors?.phone_no?.[0];
              setError({
                ...errors,
                intern_name: v1,
                email: v2,
                phone_no: v3,
              });
            }
          })
          .catch((err) => {
            setLoading(true)
            console.log(err);
          });
      }
    }
  };
  function deleteMember(user_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        Deletemembers(user_id);
      }
    });
  }
  const Deletemembers = (user_id) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN"),
        "My-Custom-Header": "foobar",
      },
    };
    fetch(`${API_URL}unassignAgent?user_id=${user_id}`, requestOptions)
      .then((response) => response.json())
      .then((actualData) => {
        if (actualData?.status == "success") {
          toast.success(actualData?.message);
          getList(1);
        } else {
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getData = (id) => {
    api
      .get(`agent/${id}`, {}, true)
      .then(async function ([success, response]) {
        setValues({
          ...values,
          intern_name: response?.data?.intern_name,
          agent_name: response?.data?.name,
          mobile_number: response?.data?.phone_no,
          email: response?.data?.email,
          role_id: response?.data?.role?.id,
          edit: 1,
          user_id: response?.data?.id,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const sendPasswordData = () => {
    if (password?.password !== password?.password_confirmation) {
      setValid(true);
    } else {
      api
        .post(
          `changepassword`,
          {
            password: password?.password,
            password_confirmation: password?.password_confirmation,
            cust_id: password?.cust_id,
          },
          true
        )
        .then(async function ([success, response]) {
          if (response?.status == "success") {
            toast.success(response?.message);
            toggleModalPass(!isShowingpass);
            getList(1);
            setPassword({
              password: "",
              password_confirmation: "",
            });
          } else {
            toast.error(response?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const [file, setFile] = useState();
  function uploadSingleFile(e) {
    console.log(e.target.files[0], "e.target.files[0]");
    setFile(e.target.files[0]);
  }

  return (
    <>
      <div className="right-content-wrap" style={{ width: "100%" }}>
        <div className="list-of-customers">
          <div className="card list-card-head p-3">
            <div className="d-flex align-items-center justify-content-between flex-wrap  gap-2">
              <div className="main-title">
                <h5 className="mb-0">List of Interns</h5>
              </div>
              <div class="head-menu-buttons d-flex align-items-center gap-3 flex-wrap">
                <a href="#" className="px-4" onClick={() => toggleModal(true)}>
                  <i
                    class="fa-sharp fa-solid fa-plus"
                    style={{ color: "#EE307F" }}
                  ></i>{" "}
                  Add Intern
                </a>
              </div>
            </div>
          </div>

          {loading ? (
            <ColorRing
              visible={true}
              height="150"
              width="150"
              ariaLabel="blocks-loading"
              wrapperStyle={{ marginLeft: "40%", marginTop: "15%" }}
              wrapperClass="blocks-wrapper"
            />
          ) : (
            <div className="row mt-4 gx-3 gy-3">
              {data?.length > 0 ? (
                <div className="col-lg-12 col-md-12">
                  <div className="table-wrap">
                    <table className="w-100">
                      <thead>
                        <th>Sl No.</th>
                        <th>Name</th>
                        {/* <th>Followup</th> */}
                        <th>Status</th>
                        <th>Actions</th>
                      </thead>
                      <tbody>
                        {data?.map((item, index) => {
                          return (
                            <>
                              <tr>
                                <td>{`${index + 1 == 10 ? (pages.CurrentPage != null ? pages.CurrentPage : 1 - 1) * (index + 1) : pages.CurrentPage != null ? pages.CurrentPage - 1 + "" + (index + 1) : null}`}</td>
                                <td>
                                  <div className="d-flex align-items-center account-image-details">
                                    <img
                                      src="images/userimage.png"
                                      class="me-2"
                                    />
                                    <div className="name_and_mail">
                                      <h6 className="mb-0">{item?.name}</h6>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {item?.status == 1 ? "Active" : "Inactive"}
                                </td>

                                <td id="view">
                                  {/* <a
                                    href="#"
                                    onClick={() => {
                                      getData(item?.id);
                                      toggleModals(true);
                                      setValues({
                                        ...values,
                                        view: 1,
                                      });
                                    }}
                                  >
                                    <img
                                      src="/images/eye.png"
                                      className="px-0"
                                    />
                                  </a> */}
                                  <a
                                    href="#"
                                    onClick={() => {
                                      getData(item?.id);
                                      toggleModals(true);
                                    }}
                                  >
                                    {" "}
                                    <img
                                      title="Edit"
                                      src="/images/pencil-lower.png"
                                      className="px-1 "
                                      style={{ width: "25px" }}
                                    />
                                  </a>
                                  <a
                                    href="#"
                                    onClick={() => {
                                      toggleModalPass(true);
                                      setPassword({
                                        ...password,
                                        cust_id: item?.id,
                                      });
                                    }}
                                  >
                                    {" "}
                                    <img
                                      title="Change Password"
                                      src="/images/lock.png"
                                      className="px-1"
                                      style={{ width: "25px" }}

                                    />
                                  </a>
                                  {item?.status == 1 ?

                                    <a
                                      href="#"
                                      onClick={() => deleteMember(item?.id)}
                                    >



                                      {/* <img
                                      src="/images/del.png"
                                      className="px-1"
                                    /> */}
                                      <MdCancel title="Delete" style={{ fontSize: "17px", marginLeft: "2%", cursor: "pointer" }} />
                                    </a>
                                    :
                                    null
                                  }
                                </td>
                              </tr>
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div style={{ marginTop: "5%" }}>
                    <Pagination
                      className="paginations"
                      activePage={pages.CurrentPage ? pages.CurrentPage : 0}
                      itemsCountPerPage={pages.Per_page ? pages.Per_page : 0}
                      totalItemsCount={pages.totalPages ? pages.totalPages : 0}
                      onChange={(pageNumber) => {
                        setpages({ ...pages, CurrentPage: pageNumber })
                      }}

                      pageRangeDisplayed={5}
                      itemClass="ul-li"
                      linkClass="page-link"
                      prevPageText="<"
                      nextPageText=">"
                      firstPageText="First"
                      lastPageText="Last"
                    />
                  </div>
                </div>
              ) : (
                <h3 style={{ marginLeft: "35%" }}>No Records Found</h3>
              )}
            </div>
          )}
        </div>
      </div>

      {isShowing == true ? (
        <>


          <AddAgentModals
            isShowing={isShowing}
            hide={toggleModal}
            value={value}
            onChange={onChange}
            handleSubmit={handleSubmit}
            USER_DATA={USER_DATA}
            saveTrigger={saveTrigger}
            setSaveTrigger={setSaveTrigger}
            setValidStatus={setValidStatus}
            validStatus={validStatus}
            valid={valid}
            errors={errors}
            setValues={setValues}
            values={values}
            setValid={setValid}
            handleSubmitEdit={handleSubmitEdit}
            loading={loading}
          />

        </>
      ) : null}
      {isShowings == true ? (
        <>
          <EditAgentModals
            isShowings={isShowings}
            hide={toggleModals}
            values={values}
            onChange={onChangeEdit}
            USER_DATA={USER_DATA}
            saveTriggers={saveTriggers}
            setValidStatuss={setValidStatuss}
            setSaveTriggers={setSaveTriggers}
            validStatuss={validStatuss}
            valid={valid}
            errors={errors}
            setValues={setValues}
            setError={setError}
            handleSubmitEdit={handleSubmitEdit}
          />
        </>
      ) : null}
      {isShowingpass == true ? (
        <>
          <ChangePasswordModals
            isShowingpass={isShowingpass}
            hide={toggleModalPass}
            password={password}
            setPassword={setPassword}
            onChange={onChangeEdit}
            USER_DATA={USER_DATA}
            saveTrigger={saveTriggerspass}
            setValidStatus={setValidStatuspass}
            setSaveTrigger={setSaveTriggerspass}
            validStatus={validStatuspass}
            valid={valid}
            errors={errors}
            setValue={setValue}
            setValid={setValid}
            handleSubmitEdit={handleSubmitEdit}
            handleChangePassword={handleChangePassword}
            onChangePassword={onChangePassword}
          />
        </>
      ) : null}
    </>
  );
}

export default AgentList;