import React from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import DropDown from "../../../components/Fields/DropDown";
const CustomerAssign = ({
  isShowing,
  hide,
  handleSubmit,
  onChange,
  agent_data,
  error,
  buttonDisable,
  setButtonDisable
}) => {
  console.log(buttonDisable,"ijrfie")
  const handleSubmits = ()=>{
    handleSubmit();
    setButtonDisable(true)
  }
  return (
    <React.Fragment>
      <Modal
        className="modal-outer"
        isOpen={isShowing}
        style={{ maxWidth: "450px" }}
      >
        <ModalHeader toggle={hide} className="border-bottom-0 px-5 pt-5">
          Jobseeker Assigning
        </ModalHeader>
        <ModalBody className="px-5 pb-5">
          <div class="upload-prod-outer">
            <Form className="form-wrap">
              
              <div className="mt-4"></div>
              <Row>
                <Col md={12}>
                  <Select
                    isSearchable
                    isClearable
                    options={agent_data}
                    onChange={(option) => onChange(option)}
                  />
                  <div style={{ color: "red" }}>{error?.agent_id}</div>
                </Col>
                <Row>
                  <Col md={12}>
                    <div className="d-flex align-items-center gap-4 justify-content-center mt-4 ">
                      <FormGroup>
                        <Button
                          color="primary"
                          className={"px-5"}
                          onClick={handleSubmits}
                          disabled = {buttonDisable}
                        >
                          Assign
                        </Button>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default CustomerAssign;