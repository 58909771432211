const constants = {
  USER_ROLES: {
    ADMIN: "Admin",
    AGENT: "Agent",
  },
  PROFILE_COMPLETION_STATUS: {
    UNASSIGNED: 0,
    FIRSTCALL: 1, 
    FIRSTFOLLOWUP: 2,
    SECONDFOLLOWUP: 3,
    DEACTIVATED:4
  },
  FOLLOW_UP_STATUS: {
    UNASSIGNED:0,
    FIRSTCALL:1,
    FIRSTFOLLOWUP:2,
    SECONDFOLLOWUP:3,
    INACTIVE:4
  },
  ASSIGNED_STATUS: {
    UNASSIGNED: 0,
    ASSIGNED: 1,
    PROGRESS: 1 || 2,
  },
};

export default constants;


