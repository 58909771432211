import React, { useState, useEffect } from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import { Blocks, ColorRing, InfinitySpin } from "react-loader-spinner";
import TextField from "../../../components/Fields/TextField";
import DropDown from "../../../components/Fields/DropDown";
const AddAgentModal = ({
  isShowing,
  hide,
  value,
  images,
  setImages,
  onChangeImage,
  handleSubmit,
  setValue,
  onChange,
  saveTrigger,
  setSaveTrigger,
  setValidStatus,
  validStatus,
  category,
  agent_data,
  USER_DATA,
  valid,
  errors,
  values,
  setValues,
  setValid,
  handleSubmitEdit,
  loading
}) => {
  const [datas, setDatas] = useState('')
  const handleHide = () =>{
    hide()
    setValid(false)
  }
  function dat() {
    setDatas(USER_DATA.filter((value) => value == "2"))
  }
  const datass = [{
    label: "Agent",
    value: 2
  }
  ]
  useEffect(() => {
    console.log(USER_DATA, "nfiej")
    dat()
  }, [loading])
  console.log(loading, "loadingds")
  return (
    <React.Fragment>
      <Modal
        className="modal-outer"
        isOpen={isShowing}
        style={{ maxWidth: "880px" }}
      >
        <ModalHeader toggle={handleHide} className="border-bottom-0 px-5 pt-5">
          Add Intern
        </ModalHeader>
        <ModalBody className="px-5 pb-5">
          <div class="upload-prod-outer">
            <Form className="form-wrap">
              <Row>
                <Col md={12}>
                  {/* <FormGroup>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </FormGroup> */}
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    <Label>Intern Name</Label>
                    <TextField
                      name="agent_name"
                      type="name"
                      placeholder="Agent Name"
                      value={value?.agent_name || values?.agent_name}
                      onChange={onChange}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setValue}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    <Label>User Name</Label>
                    <TextField
                      name="intern_name"
                      type="text"
                      placeholder="User Name"
                      value={value?.intern_name || values?.intern_name}
                      onChange={onChange}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setValue}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3 ">
                    {errors?.intern_name}
                  </div>
                </Col>
                <Col md={12} sm={6}>
                  <FormGroup className="select-wrap position-relative" style={{ zIndex:"999" }}>
                    <Label>Select Role</Label>
                    <DropDown
                      value={
                        datass?.find((wa) => {
                          return wa?.value == value?.role_id || values?.role_id;
                        }) || null
                      }
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      data={datass}
                      name="role_id"
                      onChange={onChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={6} className="">
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Mobile Number</Label>
                    <TextField
                      name="mobile_number"
                      type="phone"
                      placeholder="Mobile Number"
                      value={value?.mobile_number || values?.mobile_number}
                      onChange={onChange}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setValue}
                      max={10}
                      min={10}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3">
                    {errors?.phone_no}
                  </div>
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    <Label>Email Id</Label>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Email Id"
                      value={value?.email || values?.email}
                      onChange={onChange}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setValue}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3 ">
                    {errors?.email}
                  </div>
                </Col>
                {values?.edit == 1 ? null : (
                  <>
                    <Col md={6} sm={6}>
                      <FormGroup className="position-relative">
                        <Label>Password</Label>
                        <TextField
                          name="password"
                          type="password"
                          types="password"
                          placeholder="Password"
                          value={value?.password}
                          onChange={onChange}
                          saveTrigger={saveTrigger}
                          validStatus={validStatus}
                          setValidStatus={setValidStatus}
                          setValue={setValue}
                          required
                          className="form-control w-100 "
                          min={8}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6} sm={6}>
                      <FormGroup className="position-relative">
                        <Label>Confirm Password</Label>
                        <TextField
                          name="password_confirmation"
                          type="password"
                          types="confirm_password"
                          placeholder="Confirm Password"
                          value={value?.password_confirmation}
                          onChange={onChange}
                          saveTrigger={saveTrigger}
                          validStatus={validStatus}
                          setValidStatus={setValidStatus}
                          setValue={setValue}
                          required
                          className="form-control w-100 "
                          min={8}
                        />
                      </FormGroup>
                      {valid == true ? (
                        <div style={{ color: "red" }} className="errors mt-3 ">
                          Password & Confirm password are not same
                        </div>
                      ) : null}
                    </Col>
                  </>
                )}
                <Row>
                  <Col md={12}>
                    {/* <FormGroup>
                      <div className="agree-checkbox-wrap d-flex align-items-center gap-2">
                        <Input type="checkbox"  />
                        <Label className="mb-0">
                          Lorem Ipsum is simply dummy text of the printing{" "}
                        </Label>
                      </div>
                    </FormGroup> */}
                  </Col>
                  <Col md={12}>
                    <div className="d-flex align-items-center gap-4 justify-content-between mt-4">
                      <FormGroup className="mx-auto">
                        {values?.edit == 1 ? (
                          <Button
                            color="primary"
                            className={"px-5 modal-btn"}
                            onClick={(e) =>
                              handleSubmitEdit(e, values?.edit, values?.user_id)
                            }
                          >
                            Add Interns
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className={"px-5 modal-btn"}
                            disabled={loading}
                            onClick={(e) =>
                              handleSubmit(e, value?.edit, value?.user_id)
                            }
                          >
                            Add Intern
                          </Button>
                        )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default AddAgentModal;