import lodash from "lodash";
import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import {
  convertFieldName,
  validateNumber,
  validateEmail,
  validateFloatNumber,
  stringToRegex,
  validatePhoneNumber,
  convertFieldNameLower,
} from "../converts/Converts";
export default function TextField(props) {
  const [prevSaveTrigger, setPrevSaveTrigger] = useState(props?.saveTrigger);
  const [valid, setValid] = useState(false);
  const [showPrimaryValidation, setShowPrimaryValidation] = useState();
  const [triggeredSave, setTriggeredSave] = useState(false);
  const [invalidMessages, setInvalidMessages] = useState({
    message: "",
    validated: true,
  });
  const [showValidations, setShowValidations] = useState(false);
  const runValidations = useMemo(() => !props?.disabled === true);
  const [runValidation, setRunValidations] = useState(true);
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);
  const [warnpassword, setwarnpassword] = useState(false);
  useEffect(() => {
    if (props?.saveTrigger !== prevSaveTrigger) {
      setPrevSaveTrigger(props?.saveTrigger);
      setTriggeredSave(true);
      setRunValidations(true);
    }
  }, [props?.saveTrigger]);
  const Eye = () => {
    if (password == "password" ) {
      setpassword("text");
      seteye(false);
      settype(true);
    } else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  };
  useEffect(() => {
    if (props?.saveTrigger !== prevSaveTrigger) {
      setPrevSaveTrigger(props?.saveTrigger);
      // run validations
      setShowValidations(true);
    }
  }, [props?.saveTrigger]);
  const middleValidation = (e) => {
    if (e?.confirm_password) {
      setShowPrimaryValidation(true);
    } else {
      setShowPrimaryValidation(false);
    }
    props?.setValidStatus(e);
  };
  useEffect(() => {
    var validated = true;
    if (runValidations) {
      // start of common validations
      if (props?.required) {
        if (!props?.value) {
          validated = false;
          if (props?.custom_name) {
            setInvalidMessages({
              message: convertFieldName(props?.name) + "  required",
              validated: false,
            });
          } else if (props?.exact_name) {
            setInvalidMessages({
              message: props?.exact_name + "  required",
              validated: false,
            });
          } else {
            setInvalidMessages({
              message: convertFieldName(props?.name) + " is required",
              validated: false,
            });
          }
        }
      }
      if (validated && props?.value) {
        if (!props?.value || props?.value.toString().length < props?.min) {
          validated = false;
          setInvalidMessages({
            message: props?.customErrorMessage
              ? props?.customErrorMessage
              : convertFieldName(props?.name) +
              " should be minimum of " +
              props?.min +
              " numbers",
            validated: false,
          });
        }
      }
      if (validated && props?.max && props?.value) {
        if (!props?.value || props?.value.toString().length > props?.max) {
          validated = false;
          setInvalidMessages({
            message: props?.customErrorMessage
              ? props?.customErrorMessage
              : convertFieldName(props?.name) +
              " should not be more than " +
              props?.max +
              " numbers",
            validated: false,
          });
        }
      }
      // end of common validations
      if (validated && props?.type && props?.value) {
        // start of type based validations
        switch (props?.type) {
          case "number":
            if (!validateNumber(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: props?.customErrorMessage2
                  ? props?.customErrorMessage2
                  : convertFieldName(props?.name) + " should be only numbers",
                validated: false,
              });
            }
            break;
          case "phone":
            var pattern = new RegExp(/^[1-9]{1}[0-9]{9}$/);
            if (!pattern.test(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: "Phone Number format invalid",
                validated: false,
              });
            }
            break;
          case "email":
            if (!validateEmail(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: props?.customErrorMessage2
                  ? props?.customErrorMessage2
                  : "Please enter valid Email ID",
                validated: false,
              });
            }
            break;
          case "name":
            var pattern = new RegExp(/^[a-z ,.']+$/i);
            if (!pattern.test(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: "Must not contain any special characters or numbers",
                validated: false,
              });
            }
            break;
          case "regex":
            var pattern = new RegExp(stringToRegex(props?.regex));
            if (!pattern.test(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: props?.customErrorMessage2
                  ? props?.customErrorMessage2
                  : "Invalid Regex",
                validated: false,
              });
            }
            break;
          case "float":
            if (!validateFloatNumber(props?.value)) {
              validated = false;
              setInvalidMessages({
                message: props?.customErrorMessage2
                  ? props?.customErrorMessage2
                  : convertFieldName(props?.name) + " is invalid Float number",
                validated: false,
              });
            }
            break;
          default:
            break;
        }
      }
    }
    if (validated) {
      setInvalidMessages({
        message: "",
        validated: true,
      });
      setValid(true);
    } else {
      setValid(false);
    }
    if (
      props?.index !== undefined &&
      typeof props?.setValidStatus === "function"
    ) {
      props?.setValidStatus(props?.name, props?.index, validated);
    } else if (typeof props?.setValidStatus === "function") {
      if (!lodash.has(props.validStatus, props?.name))
        props.setValidStatus({
          ...props?.validStatus,
          [props?.name]: validated,
        });
      else if (props?.validStatus[props?.name] !== validated) {
        props.setValidStatus({
          ...props?.validStatus,
          [props?.name]: validated,
        });
      }
    }
  }, [
    runValidations,
    props?.value,
    props?.required,
    props?.min,
    props?.max,
    props?.name,
    props?.validStatus,
  ]);
  const onChange = (e) => {
    props?.onChange(e);
  };
  useEffect(() => {
    if (runValidations) {
      if (props?.password === props?.confirm_password) {
        props?.setValidStatus((data) => ({
          ...data,
          confirm_password: true,
        }));
      } else {
        props?.setValidStatus((data) => ({
          ...data,
          confirm_password: false,
        }));
      }
    }
  }, [props?.password, props?.confirm_password, runValidations]);
  return (
    props?.show !== false && (
      <>
        <h6>
          {props?.label}
          {/* {props?.required ? (
              <span
                style={{
                  color: "#58221c",
                }}
              >*
              </span>
            ) : null} */}
        </h6>
        <div className="input-group ">
          {props?.textarea ? (
            <textarea
              {...(props?.className ? { className: props?.className } : null)}
              name={props?.name}
              {...(props?.disabled ? { disabled: props?.disabled } : null)}
              {...(props?.placeholder
                ? { placeholder: props?.placeholder }
                : null)}
              type={props?.type}
              value={props?.value}
              onChange={onChange}
            />
          ) : (
            <>
              {props?.type == "password" ? (
                <>
                  <input
                    type={password}
                    className="form-control py-3 border-end-0"
                    {...(props?.placeholder
                      ? { placeholder: props?.placeholder }
                      : null)}
                    aria-label="Password"
                    aria-describedby="newpass"
                    value={props?.password}
                    onChange={onChange}
                    name={props?.name}
                    setValidStatus={middleValidation}
                  />
                  <span
                    className="input-group-text bg-white border-start-0"
                    id="newpass"
                  >
                    {props?.types == "password" || props?.types == "confirm_password"  ?
                      <i
                        onClick={Eye}
                        className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                      ></i>
                      :
                      null}
                  </span>
                </>
              ) : (
                <input
                  {...(props?.className
                    ? { className: props?.className }
                    : null)}
                  name={props?.name}
                  {...(props?.disabled ? { disabled: props?.disabled } : null)}
                  {...(props?.placeholder
                    ? { placeholder: props?.placeholder }
                    : null)}
                  type={props?.type}
                  value={props?.value}
                  onChange={onChange}
                  min={props?.min}
                />
              )}
            </>
          )}
        </div>
        <div className="">
          {props?.showValidation !== false && showValidations ? (
            <div
              style={{ color: "red", textAlign: "left", paddingLeft: " 5px" }}
              className="errors "
            >
              {invalidMessages.message}
              {props?.max &&
                (props?.type === "text" || props?.type === "name") && (
                  <span class="float-right"></span>
                )}
            </div>
          ) : (
            <div
              style={{ color: "red", textAlign: "left", paddingLeft: " 5px" }}
              className="errors "
            >
              {props?.max &&
                (props?.type === "text" || props?.type === "name") && (
                  <span class="float-right"></span>
                )}
            </div>
          )}
        </div>
      </>
    )
  );
}
TextField.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  showValidation: PropTypes.bool,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  required: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
  validStatus: PropTypes.object,
  saveTrigger: PropTypes.number.isRequired,
  setValidStatus: PropTypes.func,
  customErrorMessage: PropTypes.string,
  readonly: PropTypes.bool,
};