import constants from "./constant";
const USER_DATA = [
  {
    label: "Agent",
    value: 2,
  },
  // {
  //   label: "Admin",
  //   value: 1,
  // },
];
const FOLLOW_DATA = [
  {
    label: "Assigned Calls",
    value: 6,
  },
  {
    label: "First Call Pending",
    value: "0",
  },
  {
    label: "First Follow up Pending ",
    value: "1",
  },
  {
    label: "Second Follow up Pending ",
    value: "2",
  },
  {
    label: "Active",
    value: "3",
  },
  // {
  //   label: "Completed Profiles",
  //   value: 3,
  // },
  {
    label: "Inactive",
    value: 4,
  },
  {
    label: "Archived",
    value: 5,
  },
 
  // {
  //   label: "Unassigned",
  //   value: 7,
  // },
];
export { USER_DATA,FOLLOW_DATA };