import React from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";

import TextField from "../../../components/Fields/TextField";
import DropDown from "../../../components/Fields/DropDown";
import  {useState} from "react";

const ChangePasswordModal = ({
  isShowingpass,
  hide,
  saveTrigger,
  setValidStatus,
  validStatus,
  valid,
  handleChangePassword,
  onChangePassword,
  password,
  setPassword,
  setValid,
}) => {
 const handleHide = () =>{
  hide()
  setValid(false)
 }
  const [eye, seteye] = useState(true);
  const [passwords, setpasswords] = useState("password");

  const Eye = () => {
    if (passwords == "password") {
      setpasswords("text");
      seteye(false);
    } else {
      setpasswords("password");
      seteye(true);
    }
  };

  return (
    <React.Fragment>
      <Modal
        className="modal-outer"
        isOpen={isShowingpass}
        style={{ maxWidth: "880px" }}
      >
        <ModalHeader toggle={handleHide} className="border-bottom-0 px-5 pt-5">
          Change Password
        </ModalHeader>

        <ModalBody className="px-5 pb-5">
          <div class="upload-prod-outer">
            <Form className="form-wrap">
              <Row>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Password</Label>
                    <TextField
                      name="password"
                      type="password"
                      types="password"
                      placeholder="Password"
                      value={password?.password}
                      onChange={onChangePassword}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setPassword}
                      required
                      className="form-control w-100 "
                      min={8}
                    />
                     {/* <i
                      onClick={Eye}
                      className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}

                    ></i> */}
                  </FormGroup>
                </Col>

                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Confirm Password</Label>
                    <TextField
                      name="password_confirmation"
                      type="password"
                      types="confirm-password"
                      placeholder="Password"
                      value={password?.password_confirmation}
                      onChange={onChangePassword}
                      saveTrigger={saveTrigger}
                      validStatus={validStatus}
                      setValidStatus={setValidStatus}
                      setValue={setPassword}
                      required
                      className="form-control w-100 "
                      min={8}
                    />
                  </FormGroup>
                  {valid == true ? (
                    <div style={{ color: "red" }} className="errors mt-3 ">
                      Password & Confirm password are not same
                    </div>
                  ) : null}
                </Col>

                <Row>
                  {/* <Col md={12}>
                    <FormGroup>
                      <div className="agree-checkbox-wrap d-flex align-items-center gap-2">
                        <Input type="checkbox" />
                        <Label className="mb-0">
                          Lorem Ipsum is simply dummy text of the printing{" "}
                        </Label>
                      </div>
                    </FormGroup>
                  </Col> */}
                  <Col md={12}>
                    <div className="d-flex align-items-center gap-4 justify-content-between mt-4">
                      <FormGroup className="mx-auto">
                        <Button
                          color="primary"
                          className={"px-5 modal-btn"}
                          onClick={handleChangePassword}
                        >
                          Save
                        </Button>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ChangePasswordModal;
