import React from 'react';
import Header from '../layouts/Header';
import SideBar from '../layouts/Sidebar';
import Customerlist from '../customer/Customerlist';



function Customerlisting() {



  return (
    <>

<div class="page-wrapper">
 {/* <Header/> */}
     <div class="row m-0">
      {/* <SideBar/> */}
            <div class="col-md-10 p-4 " id="content" style={{width:"100%"}}>
             
            <Customerlist/>

            </div>
    </div>

    
</div>
    </>
  );

}

export default Customerlisting;