import DonutChart from "react-donut-chart";
import { useState, useEffect, useContext, useMemo } from "react";
import { SidebarContext } from "../context/ContextState";

export default function ProgressChart({ data, summary }) {
  const roll = localStorage.getItem("role_id");
  console.log(roll,"ldkkfnkef")
  const {daval} = useContext(SidebarContext);
  var fc = ""
  var ffc = ""
  var sfc = ""
  if( roll== "Admin"){

    console.log(data,"og7t6rthoij")
    // fc = data?.totafirstcallcount
    // ffc = data?.totalfirstfollowupcount
    // sfc = data?.secondfollowupcount
    fc =
      summary === "1"
        ? data?.totafirstcallcount_week || 0
        : summary === "2"
        ? data?.totafirstcallcount_month || 0
        : summary === "3"
        ? data?.totafirstcallcount_year || 0
        : data?.totafirstcallcount || 0;

    ffc =
      summary === "1"
        ? data?.totalfirstfollowupcount_week || 0
        : summary === "2"
        ? data?.totalfirstfollowupcount_month || 0
        : summary === "3"
        ? data?.totalfirstfollowupcount_year || 0
        : data?.totalfirstfollowupcount || 0;

    sfc =
      summary === "1"
        ? data?.secondfollowupcount_week || 0
        : summary === "2"
        ? data?.secondfollowupcount_month || 0
        : summary === "3"
        ? data?.secondfollowupcount_year || 0
        : data?.secondfollowupcount || 0;
  }
  else{
    // fc = daval?.totafirstcallcount
    // ffc = daval?.totalfirstfollowupcount
    // sfc = daval?.secondfollowupcount
    fc = 
    summary === "1"
    ? daval?.totafirstcallcount_week || 0
    : summary === "2"
    ? daval?.totafirstcallcount_month || 0
    : summary === "3"
    ? daval?.totafirstcallcount_year || 0
    : daval?.totafirstcallcount || 0;

    ffc = 
    summary === "1"
    ? daval?.totalfirstfollowupcount_week || 0
    : summary === "2"
    ? daval?.totalfirstfollowupcount_month || 0
    : summary === "3"
    ? daval?.totalfirstfollowupcount_year || 0
    : daval?.totalfirstfollowupcount || 0;
    
    sfc =  summary === "1"
    ? daval?.secondfollowupcount_week || 0
    : summary === "2"
    ? daval?.secondfollowupcount_month || 0
    : summary === "3"
    ? daval?.secondfollowupcount_year || 0
    : daval?.secondfollowupcount || 0;
  }


  useEffect(()=>{

  },[])

  return (
    <>
      <DonutChart
        width={300}
        height={218}
        colors={["#3CABDB", "#DE5F76", "#5A82B5"]}
        data={[
          {
            label: (roll == "Admin" ? "First call completed" : "First call"),
            value: fc,
          },
          {
            label: (roll == "Admin" ? "First follow up completed" : "First follow up" ),
            value: ffc,
          },
          {
            label: (roll == "Admin" ? "Second follow up completed" : "Second follow up"),
            value: sfc,
          },
        ]}
        strokeColor={false}
        outerRadius={0.8}
        innerRadius={0.9}
        legend={true}
      />
    </>
  );
}