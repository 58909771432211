import logo from "./logo.svg";
import React, { useEffect, useMemo, useState, createContext } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import AgentLogin from "./components/customer/AgentLogin";
import Dashboard from "./components/dashboard/Dashboard";
// Screens
import Customerlisting from "./components/pages/customer";
import Firstcalllisting from "./components/customer/Firstcalllisting";
import Firstfollowupcalllisting from "./components/customer/First_followup_list";
import Secondfollowupcalllisting from "./components/customer/Second_followup_list";
import PrivateRoute from "./route/PrivateRoute";
import Header from "./components/layouts/Header";
import Sidebar from "./components/layouts/Sidebar";
import { SidebarProvider } from "./components/context/ContextState";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminDashboard from "./pages/Admin/Dashboard/AdminDashboard";
import AgnetList from "./pages/Admin/AgentList/AgentList";
import CustomerList from "./pages/Admin/Customer/CustomerList";
import Reports from "./pages/Reports/Reports";
import CustomerStatus from "./components/customer/customerstatus";

function App() {
  const [sidebarload, setSidebarload] = useState(true);

  const Layout = (Element, data, setSidebarload) => {
    return (
      <>
        <div className="row m-0">
          <Header data={data} />
          <Sidebar data={data} />
          <div className="col-md-10 p-4 dashboard offset-md-2" id="content">
            <ToastContainer
              position="top-right"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
            <Element />
          </div>
        </div>
      </>
    );
  };
  return (
    <>
      <SidebarProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<AgentLogin />} />
            <Route element={<PrivateRoute />}>
              <Route
                path="/firstcalllisting"
                element={
                  sidebarload
                    ? Layout(Firstcalllisting, "Firstcalllisting")
                    : null
                }
              />
              <Route
                path="/first_call_followup_customer_list"
                element={
                  sidebarload
                    ? Layout(
                        Firstfollowupcalllisting,
                        "Firstfollowupcalllisting"
                      )
                    : null
                }
              />
              <Route
                path="/second_call_followup_customer_list"
                element={
                  sidebarload
                    ? Layout(
                        Secondfollowupcalllisting,
                        "Secondfollowupcalllisting"
                      )
                    : null
                }
              />
              <Route
                path="/dashboard"
                element={sidebarload ? Layout(Dashboard, "Dashboard") : null}
              />
              <Route
                path="/customerlisting"
                element={
                  sidebarload
                    ? Layout(Customerlisting, "Customerlisting")
                    : null
                }
              />

              <Route
                path="/customerstatus"
                element={
                  sidebarload ? Layout(CustomerStatus, "CustomerStatus") : null
                }
              />

              <Route
                path="/admin_dashboard"
                element={
                  sidebarload ? Layout(AdminDashboard, "Dashboard") : null
                }
              />
              <Route
                path="/admin_agent_list"
                element={sidebarload ? Layout(AgnetList, "Agent List") : null}
              />
              <Route
                path="/admin_customer_list"
                element={
                  sidebarload ? Layout(CustomerList, "Agent List") : null
                }
              />
              <Route
                path="/reports"
                element={sidebarload ? Layout(Reports, "Reports") : null}
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </SidebarProvider>
    </>
  );
}
export default App;
