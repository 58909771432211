import React, { useContext, useEffect } from "react";

import { useState } from "react";
import { MdCancel } from "react-icons/md";
import { SidebarContext } from "../context/ContextState";
import { API_URL } from "../api/api_settings";
import ReactTimeAgo from "react-time-ago";
import { api } from "../../components/lib/base";
import "../dashboard/style.css";
import { Blocks, ColorRing, InfinitySpin } from "react-loader-spinner";

function CustomerFollowup({
  followupsubmit,
  followupsubmit1,
  followupsubmit2,
  options,
  data,
  da1,
  datass,
  handleClick,
  handleClicks1,
  handleClicks2,
  handleClicks3,
  handleClicks4,
  value,
  li,
  li2,
  vi,
  vi1,
  li1,
  da,
  checkedval,
}) {

  console.log(da,"wekwfiefi")
  const { viewdisables, tri1 } = useContext(SidebarContext);

  var da_filter = [];
  da_filter = da?.filter((item) => item.is_check == true)

  console.log(da_filter,"efnrioe")
  const [values, setValue] = useState({
    dwms_intrst: "1",
    not_intrstd_reason: "",
    job_visibity: "1",
    availabale_career_service: "1",
    specific_service: "",
    profile_incomplete_reason: "",
    gotjob:"1",
    availed_career_support_data:"",
    availed_career_service: "1",
    availed_career_support_reason:""
  });

  console.log(da,"daaaa")
  const [logs1, setlogs1] = useState([]);
  const [logs2, setlogs2] = useState([]);
  const [logs3, setlogs3] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // const checkval = async () => {
  //   api
  //     .get(`firstCallCompleteStep?cust_id=${data?.id}`, {}, true)
  //     .then(async function ([success, response]) {
  //       let editSocialId = [];
  //       response?.data.map((item) => {
  //         const { id } = item;
  //         editSocialId.push(id);
  //       });

  //       setCheckedval(editSocialId);
  //       setLi([String(...new Set(editSocialId))]);

  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getlog1 = () => {
    setIsLoading(true);
    api
      .get(`calllogs?call_type=1`, {}, true)
      .then(async function ([success, response]) {
        setlogs1(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  const getlog2 = () => {
    setIsLoading(true);

    api
      .get(`calllogs?call_type=2`, {}, true)
      .then(async function ([success, response]) {
        setlogs2(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  const getlog3 = () => {
    setIsLoading(true);

    api
      .get(`calllogs?call_type=3`, {}, true)
      .then(async function ([success, response]) {
        setlogs3(response?.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(true);
      });
  };

  const onChange = (e, field_name = null) => {
    setValue({
      ...values,
      [field_name ? field_name : e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    getlog1();
    getlog2();
    getlog3();
    console.log(da1,"dlgfgnofke")
  }, [
    values?.dwms_intrst,
    values?.not_intrstd_reason,
    values?.specific_service,
    values?.gotjob,
    options,
    data,
    data.dis,
    data.is_check,
    da,
    da1,
    values?.job_visibity,
  ]);

  return (
    <>
      <div class="col-md-6 det" id="det">
        <div class="row gy-3 gx-3">
          <div class="col-md-12">
            <div class="call-followup-status-wrap">
              <div class="card bg-white border-0">
                {options != 3 ? (
                  <>
                    <div class="card-header bg-white d-flex  gap-4 px-2rem py-3 pt-0 border-bottom-0">
                      <h6 class="mb-0 text-center">Call Follow up status</h6>
                      {/* <button onClick={closes}> <MdCancel /></button> */}
                    </div>
                    <div class="card-body">
                      <input
                        name="follow_up_status"
                        value={options == " " && options == 0 ? "First Call" : options == 1 ? "First Followup" : options == 2 ? "Second Followup" : null}
                        class="form-input"
                        id="MyFollowUpTab"
                        role="tablist"
                        onChange={onChange}
                        style={{ float: "left",backgroundColor:"#dad7d7" }}
                        disabled
                      />
                        {/* {options == " " && options == 0 ? (
                          <option value="0" selected name="firstcall">
                            First Call
                          </option>
                        ) : null}

                        {options == 1 ? (
                          <option
                            value="1"
                            selected
                            name="firstfollowup
                        "
                          >
                            First Followup
                          </option>
                        ) : null}

                        {options == 2 ? (
                          <option value="2" name="secondfollowup" selected>
                            Second Followup
                          </option>
                        ) : null} */}
                      
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>

          {/* start first qstn */}
          {(options == "" || options == "0") && (
            <>
              <div class="col-md-12">
                <div class="followup-Ques-wrap">
                  <div class="card bg-white border-0">
                    <div class="card-header bg-white px-2rem py-3 border-bottom-0">
                      <h6 class="mb-0">First Call Questions</h6>
                    </div>
                    <hr class="pink mb-0" />
                    <div class="card-body pt-0 pb-5">
                      <ul class="questions-followup">

                      {value?.call_connection_status == "1" ||
                      value?.call_connection_status == "" ? (
                        <>
                          {" "}
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Are you interested in DWMS ?
                            </span>
                            
                            <div>
                              <select
                                name="dwms_intrst"
                                value={values?.dwms_intrst}
                                class="form-select"
                                id="MyFollowUpTab"
                                role="tablist"
                                onChange={onChange}
                              >
                                <option value="1" name="yes" selected="">
                                  Yes{" "}
                                </option>
                                <option value="0" name="no">
                                  No
                                </option>
                              </select>
                            </div>
                          </li>
                          <hr class="pink" />
                          {/* start */}
                          {values.dwms_intrst == "0" ? null : (
                            <li className="my-4">
                              {" "}
                              <span
                                // style={{ fontSize: "18px", fontWeight: "500" }}
                              >
                                 Mark profile completed fields
                              </span>
                              <div class="d-flex flex-column gap-2 flex-wrap">
                               
                                {datass?.map((item) => {
                                  return (
                                    <>
                                      <div class="form-check" key={item?.id}>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          onChange={handleClicks1}
                                          value={item?.id}
                                          name={item?.name}
                                          id={item?.id}
                                          isselected={
                                            li == item?.id ? item?.id : null
                                          }
                                          // onClick={(e) => {
                                          //   handleCheck(e);
                                          // }}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </li>
                          )}
                          {/* end */}
                          {values.dwms_intrst != "0" ? null : (
                            <>
                            
                              <li className="my-4">
                              <span>Not Interested Reason</span>
                                <div class=" not-interest-text-wrap">
                                  <textarea class="form-control" onChange={onChange}
                                   value={values.not_intrstd_reason} name="not_intrstd_reason" id="floatingTextarea01"
                                   placeholder="Leave a comment here"></textarea>
                                  {/* <label for="floatingTextarea01">
                                    Not interested reason
                                  </label> */}
                                </div>
                              </li>
                            </>
                          )}
                         
                        </>
                      ) : null}
                       </ul>

                      <div class="submit-btn text-center">
                        <a
                          href="#"
                          class="btn btn-primary"
                          onClick={(e) => {
                            followupsubmit({
                              cust_id: data?.id,
                              education: data?.education
                                ? data?.education
                                : "nill",
                              dwms_interest: values?.dwms_intrst,
                              first_call_datas: li,
                              not_intrstd_reason: values?.not_intrstd_reason,
                              options: options + 1,
                            });
                          }}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="das2 col-lg-12">
                <div class="card border-0 custom-shadow h-100">
                  <div class="call-logs-wrap">
                    <div class="card-header bg-white px-2rem">
                      <div class="d-flex align-items-center justify-content-between py-3">
                        <h6
                          class="mb-0"
                          style={{ fontSize: "18px", fontWeight: "500" }}
                        >
                          Timeline
                        </h6>
                      </div>
                    </div>

                    <div class="card-body p-2rem px-0">
                      <div class="calls-logs-content">
                        {isLoading ? (
                          <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{
                              marginLeft: "30%",
                              marginTop: "5%",
                            }}
                            wrapperClass="blocks-wrapper"
                          />
                        ) : (
                          <>
                          {logs1?.length > 0 ?
                            logs1.map((it) => {
                              return (
                                <>
                                  <div class="d-flex align-items-center justify-content-between px-2rem">
                                    <div class="left-side">
                                      <div class="d-flex align-items-center avatar-dropdown-image"> */}
                                        {/* <img src="images/accountpic.png" class="me-3" /> */}
                                        {/* <div class="name_and_mail">
                                          <h6 class="mb-0">
                                            {it?.name ? it?.name : "-"}
                                            <br />
                                            <small>
                                              {it?.mobile_no
                                                ? it?.mobile_no
                                                : "-"}
                                            </small>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="right-side">
                                      <small>
                                        <span>
                                          {
                                            <ReactTimeAgo
                                              date={
                                                it?.followup_date
                                                  ? it?.followup_date
                                                  : new Date()
                                              }
                                              locale="en-US"
                                            />
                                          }
                                        </span>
                                      </small>
                                    </div>
                                  </div>
                                  <hr class="grey-stripes" />
                                </>
                                  );
                                })
                                :
                                <p style={{ marginLeft: "5%" }}>No Records Found</p>
                              }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}

          {/* end one qstn */}

          {/* start second */}
          {options == 1 && (
            <>
              <div class="col-md-12">
                <div class="followup-Ques-wrap">
                  <div class="card bg-white border-0">
                    <div class="card-header bg-white px-2rem py-3 border-bottom-0">
                      <h6 class="mb-0">First Follow up Questions</h6>
                    </div>
                    <hr class="pink mb-0"/>

                    <div class="card-body pt-0 pb-5">
                      <ul class="questions-followup">

                      {value?.call_connection_status == "1" ||
                      value?.call_connection_status == "" ? (
                        <>
                          {" "}
                          <li className="my-4">
                            {" "}
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Mark profile completed fields
                            </span>
                            <div class="d-flex flex-column gap-2 flex-wrap" id={`${vi ? "cathides" : null}`}>
                              {datass?.map((item, index) => {
                                console.log(item, "shgfjd");
                                return (
                                  <>
                                    <div class="form-check" key={item?.id}>
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        onChange={() => handleClick(index)}
                                        key={item?.id}
                                        value={item?.id}
                                        name={item?.name}
                                        id={item?.id}
                                        checked={item?.is_check}
                                        disabled={item?.dis == 1 ? true : false}
                                      />
                                      <label
                                        class="form-check-label"
                                        for={item?.name}
                                      >
                                        {item?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </li>
                         
                          <hr class="pink" />
                          {/* start */}
                          <li
                            class="my-4"
                            // style={{ fontSize: "18px", fontWeight: "500" }}
                          >
                            <span>Whether Recommended Jobs visible in profile ?</span>
                            <select
                              name="job_visibity"
                              value={values.job_visibity}
                              class="form-select"
                              id="MyFollowUpTab"
                              role="tablist"
                              onChange={onChange}
                            >
                              <option value="1" name="yes" selected="">
                                Yes{" "}
                              </option>
                              <option value="0" name="no">
                                No
                              </option>
                            </select>
                          </li>
                          {/* end */}
                          <hr class="pink" />
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Interested in Career Support services?
                            </span>

                            <select
                              name="availabale_career_service"
                              value={values?.availabale_career_service}
                              class="form-select"
                              id="MyFollowUpTab"
                              role="tablist"
                              onChange={onChange}
                            >
                              <option value="1" name="yes" selected="">
                                Yes{" "}
                              </option>
                              <option value="0" name="no">
                                No
                              </option>
                            </select>
                          </li>

                          {values?.availabale_career_service != "1" ? <>
                                <span
                                // style={{ fontSize: "18px", fontWeight: "500" }}
                                >
                                  Not Interested Reason
                                </span>
                                <div class=" not-interest-text-wrap">
                                  <textarea
                                    class="form-control"
                                    onChange={onChange}
                                    placeholder="Leave a reason here"
                                    value={values?.availed_career_support_reason}
                                    name="availed_career_support_reason"
                                    id="floatingTextarea01"
                                  ></textarea>
                                  {/* <label for="floatingTextarea01">
                                Service unused reason
                              </label> */}
                                </div></> : (
                              <>

                                <div class="d-flex flex-column gap-2 flex-wrap " style={{ fontSize: "15px", fontWeight: "500" }} >

                                  {da?.map((item) => {
                                    return (
                                      <>
                                        <div class="form-check" key={item?.id}>
                                          <input
                                            class="form-check-input"
                                            type="checkbox"
                                            onChange={handleClicks2}
                                            value={item?.id}
                                            name={item?.name}
                                            id={item?.id}
                                            isselected={
                                              li1 == item?.id ? item?.id : null
                                            }
                                          // onClick={(e) => {
                                          //   handleCheck(e);
                                          // }}
                                          />
                                          <label
                                            class="form-check-label"
                                            for="flexCheckDefault"
                                          >
                                            {item?.name}
                                          </label>
                                        </div>
                                      </>
                                    );
                                  })}
                                </div>
                              </>
                            )}
                         
                        </>
                      ) : null}
                      </ul>
                      <div class="submit-btn text-center">
                        <a
                          href="#"
                          class="btn btn-primary"
                          onClick={(e) => {
                            followupsubmit1({
                              cust_id: data?.id,
                              job_visibility: values?.job_visibity,
                              intrested_in_career_support:
                                values?.availabale_career_service,
                              first_Followup_call_data: li,
                              options: options + 1,
                              career_support_data : li1
                            });
                          }}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div class="das2 col-lg-12">
                <div class="card border-0 custom-shadow h-100">
                  <div class="call-logs-wrap">
                    <div class="card-header bg-white px-2rem">
                      <div class="d-flex align-items-center justify-content-between py-3">
                        <h6
                          class="mb-0"
                          style={{ fontSize: "18px", fontWeight: "500" }}
                        >
                          Timeline
                        </h6>
                      </div>
                    </div>

                    <div class="card-body p-2rem px-0">
                      <div class="calls-logs-content">
                        {isLoading ? (
                          <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{
                              marginLeft: "30%",
                              marginTop: "5%",
                            }}
                            wrapperClass="blocks-wrapper"
                          />
                        ) : (
                          <>
                          {logs2?.length >0 ?
                            logs2.map((it) => {
                              return (
                                <>
                                  <div class="d-flex align-items-center justify-content-between px-2rem">
                                    <div class="left-side">
                                      <div class="d-flex align-items-center avatar-dropdown-image"> */}
                                        {/* <img src="images/accountpic.png" class="me-3" /> */}
                                        {/* <div class="name_and_mail">
                                          <h6 class="mb-0">
                                            {it?.name ? it?.name : "-"}
                                            <br />
                                            <small>
                                              {it?.mobile_no
                                                ? it?.mobile_no
                                                : "-"}
                                            </small>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="right-side">
                                      <small>
                                        <span>
                                          {
                                            <ReactTimeAgo
                                              date={
                                                it?.followup_date
                                                  ? it?.followup_date
                                                  : new Date()
                                              }
                                              locale="en-US"
                                            />
                                          }
                                        </span>
                                      </small>
                                    </div>
                                  </div>
                                  <hr class="grey-stripes" />
                                </>
                                );
                              })
                              :
                              <p style={{ marginLeft: "5%" }}>No Records Found</p>
                            }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}

          {/* end second */}

          {/* start third  */}

          {options == 2 && (
            <>
              <div class="col-md-12">
                <div class="followup-Ques-wrap">
                  <div class="card bg-white border-0">
                    <div class="card-header bg-white px-2rem py-3 border-bottom-0">
                      <h6 class="mb-0">Second Follow up Questions</h6>
                    </div>
                    <hr class="pink mb-0"/>
                    <div class="card-body pt-0 pb-5">
                      <ul class="questions-followup">
                     
                      {value?.call_connection_status == "1" ||
                      value?.call_connection_status == "" ? (
                        <>
                         
                          <li className="my-4">
                            
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Mark profile completed fields
                            </span>
                        
                            <div class="d-flex flex-column gap-2 flex-wrap" id={`${vi ? "cathides" : null}`}>
                              {datass?.map((item,index) => {
                                return (
                                  <>
                                    <div class="form-check" key={item?.id}>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        onChange={() => handleClick(index)}
                                        key={item?.id}
                                        value={item?.id}
                                        name={item?.name}
                                        id={item?.id}
                                        checked={item?.is_check}
                                        disabled={item?.dis == 1 ? true : false}
                                      />
                                      <label
                                        class="form-check-label"
                                        for="flexCheckDefault"
                                      >
                                        {item?.name}
                                      </label>
                                    </div>
                                  </>
                                );
                              })}
                            </div>
                          </li>
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Whether applied for jobs ?
                            </span>
                            <select
                              name="gotjob"
                              value={values?.gotjob}
                              class="form-select"
                              id="MyFollowUpTab"
                              role="tablist"
                              onChange={onChange}
                            >
                              <option value="1" name="yes" selected="">
                                Yes{" "}
                              </option>
                              <option value="0" name="no">
                                No
                              </option>
                            </select>
                          </li>
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Interest in Career Service ?
                            </span>
                            <select
                              name="availabale_career_service"
                              value={values?.availabale_career_service}
                              class="form-select"
                              id="MyFollowUpTab"
                              role="tablist"
                              onChange={onChange}
                            >
                              <option value="1" name="yes" selected="">
                                Yes{" "}
                              </option>
                              <option value="0" name="no">
                                No
                              </option>
                            </select>
                          </li>

                          
                          {values?.availabale_career_service != "1" ? 
                          <>
                          <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Not Interested Reason
                            </span>
                          <div class=" not-interest-text-wrap">
                              <textarea
                                class="form-control"
                                onChange={onChange}
                                placeholder="Leave a reason here"
                                value={values?.availed_career_support_reason}
                                name="availed_career_support_reason"
                                id="floatingTextarea01"
                              ></textarea>
                              {/* <label for="floatingTextarea01">
                                Service unused reason
                              </label> */}
                            </div></> : (
                            <>
                              <div class="d-flex flex-column gap-2 flex-wrap" id={`${vi1 ? "cathides" : null}`}>
                              
                                {da_filter?.map((item,index) => {
                                  return (
                                    <>
                                      <div class="form-check" key={item?.id}>
                                      {item?.is_check  ?
                                        <>

                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          onChange={() => handleClicks3(index)}
                                          key={item?.id}                      
                                          value={item?.id}
                                          name={item?.name}
                                          id={item?.id}
                                          isselected={
                                            li1 == item?.id ? item?.id : null
                                          }
                                          checked={item?.is_check}
                                          disabled={item?.dis == 1 ? true : false}
                                          // onClick={(e) => {
                                          //   handleCheck(e);
                                          // }}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {item?.name}
                                        </label>
                                        </>
                                        :
                                        <p style={{display:"none"}}>fjie</p>}
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                            </>
                          )}

                          <hr class="pink" />
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Whether availed any Career support services?
                            </span>
                            
                            <div>
                              <select
                                name="availed_career_service"
                                value={values?.availed_career_service}
                                class="form-select"
                                id="MyFollowUpTab"
                                role="tablist"
                                onChange={onChange}
                              >
                                <option value="1" name="yes" selected="">
                                  Yes{" "}
                                </option>
                                <option value="0" name="no">
                                  No
                                </option>
                              </select>
                            </div>
                          </li> 
                          {/* start */}
                          {values.availed_career_service == "0" ? 
                          <>
                           <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Service Unused Reason
                            </span>

                            <div class=" not-interest-text-wrap">
                              <textarea
                                class="form-control"
                                onChange={onChange}
                                placeholder="Leave a comment here"
                                value={values?.profile_incomplete_reason}
                                name="profile_incomplete_reason"
                                id="floatingTextarea01"
                              ></textarea>
                              {/* <label for="floatingTextarea01">
                                Service unused reason
                              </label> */}
                            </div>
                            </> : (
                    
                              <div class="d-flex flex-column gap-2 flex-wrap">
                               
                                {da?.map((item) => {
                                  return (
                                    <>
                                      <div class="form-check" key={item?.id}>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          onChange={handleClicks4}
                                          value={item?.id}
                                          name={item?.name}
                                          id={item?.id}
                                          isselected={
                                            li2 == item?.id ? item?.id : null
                                          }
                                          // onClick={(e) => {
                                          //   handleCheck(e);
                                          // }}
                                        />
                                        <label
                                          class="form-check-label"
                                          for="flexCheckDefault"
                                        >
                                          {item?.name}
                                        </label>
                                      </div>
                                    </>
                                  );
                                })}
                              </div>
                          )}
                          {/* end */}

                          <hr class="pink" />
                          <li className="my-4">
                            <span
                              // style={{ fontSize: "18px", fontWeight: "500" }}
                            >
                              Any specific service needed?
                            </span>
                            <input
                              name="specific_service"
                              value={values?.specific_service}
                              class="form-control"
                              id="MyFollowUpTab"
                              type="text"
                              role="tablist"
                              onChange={onChange}
                            />
                              
                          </li>
                          
                         
                        </>
                      ) : null}
                      </ul>
                            <div class="submit-btn text-center">
                        <a
                          href="#"
                          class="btn btn-primary"
                          onClick={(e) => {
                            followupsubmit2({
                              cust_id: data?.id,
                              specific_service: values?.specific_service,
                              profile_incomplete_reason:
                                values?.profile_incomplete_reason,
                              availabale_career_service:
                                values?.availabale_career_service,
                              second_Followup_call_data: li,
                              options: options + 1,
                              career_support_data : li1,
                              gotjob:values?.gotjob,
                              availed_career_support_reason:values?.availed_career_support_reason

                            });
                          }}
                        >
                          Submit
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
{/* 
              <div class="das2 col-lg-12">
                <div class="card border-0 custom-shadow h-100">
                  <div class="call-logs-wrap">
                    <div class="card-header bg-white px-2rem">
                      <div class="d-flex align-items-center justify-content-between py-3">
                        <h6
                          class="mb-0"
                          style={{ fontSize: "18px", fontWeight: "500" }}
                        >
                          Timeline
                        </h6>
                      </div>
                    </div>

                    <div class="card-body p-2rem px-0">
                      <div class="calls-logs-content">
                        {isLoading ? (
                          <ColorRing
                            visible={true}
                            height="80"
                            width="80"
                            ariaLabel="blocks-loading"
                            wrapperStyle={{
                              marginLeft: "30%",
                              marginTop: "5%",
                            }}
                            wrapperClass="blocks-wrapper"
                          />
                        ) : (
                          <>
                          {logs3?.length > 0 ?
                            logs3.map((it) => {
                              return (
                                <>
                                  <div class="d-flex align-items-center justify-content-between px-2rem">
                                    <div class="left-side">
                                      <div class="d-flex align-items-center avatar-dropdown-image"> */}
                                        {/* <img src="images/accountpic.png" class="me-3" /> */}
                                        {/* <div class="name_and_mail">
                                          <h6 class="mb-0">
                                            {it?.name ? it?.name : "-"}
                                            <br />
                                            <small>
                                              {it?.mobile_no
                                                ? it?.mobile_no
                                                : "-"}
                                            </small>
                                          </h6>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="right-side">
                                      <small>
                                        <span>
                                          {
                                            <ReactTimeAgo
                                              date={
                                                it?.followup_date
                                                  ? it?.followup_date
                                                  : new Date()
                                              }
                                              locale="en-US"
                                            />
                                          }
                                        </span>
                                      </small>
                                    </div>
                                  </div>
                                  <hr style={{ color: "grey" }} />
                                </>
                              );
                            })
                            :
                            <p style={{ marginLeft: "5%" }}>No Records Found</p>
                          }
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </>
          )}

          {/* end start */}
          {/* <div class="col-md-12">
            <div class="timeline-card-wrap">
              <div class="card bg-white border-0">
                <div class="card-header bg-white px-2rem py-3">
                  <h6 class="mb-0">Timeline</h6>
                </div>
                <div class="card-body px-2rem">
                  <div class="timeline-content">
                    <div class="row gy-3">
                      <div class="col-md-12">
                        <h6>
                          <a href="">Agent 1</a>
                        </h6>
                        <div class="d-flex align-items-center justify-content-between">
                          <small>
                            2022-12-17&nbsp;<span>12:27:45</span>
                          </small>
                          <small>
                            <span class="text-pink">Outgoing</span>&nbsp;6 min
                            21 sec
                          </small>
                        </div>
                      </div>
                      <hr class="pink" />
                      <div class="col-md-12">
                        <h6>
                          <a href="">Agent 2</a>
                        </h6>
                        <div class="d-flex align-items-center justify-content-between">
                          <small>
                            2022-12-17&nbsp;<span>12:27:45</span>
                          </small>
                          <small>
                            <span class="text-pink">Outgoing</span>&nbsp;6 min
                            21 sec
                          </small>
                        </div>
                      </div>
                      <hr class="pink" />
                      <div class="col-md-12">
                        <h6>
                          <a href="">Agent 3</a>
                        </h6>
                        <div class="d-flex align-items-center justify-content-between">
                          <small>
                            2022-12-17&nbsp;<span>12:27:45</span>
                          </small>
                          <small>
                            <span class="text-pink">Outgoing</span>&nbsp;6 min
                            21 sec
                          </small>
                        </div>
                      </div>
                      <hr class="pink" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default CustomerFollowup;
