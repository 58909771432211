import DisabledContext from "antd/es/config-provider/DisabledContext";
import React, { createContext, useState, useEffect, useMemo, memo } from "react";
import { API_URL } from "../api/api_settings";
import constants from "../constants/constant";
import { api } from "../lib/base";


const SidebarContext = createContext();

const SidebarProvider = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState('');

  const [firstcallcount, setfirstcallcount] = useState(false);
  const [customercount, setCustomercount] = useState(0);
  const [callcount, setCallCount] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [tri, setTri] = useState(false);
  const [tri1, setTri1] = useState(false);
  const [disab, setDisab] = useState(false);
  const [daval, setDaval] = useState(false);
  const [tabopen, setTabopen] = useState(false);
  const [firstcallfollowupcount, setfirstcallfollowupcount] = useState(false);
  const [reloads, setReloads] = useState("");
  const [secondcallfollowupcount, setsecondcallfollowupcount] = useState(false);
  const [datas, setData] = useState({})
  const role_id = localStorage.getItem("role_id");
  const [sec_data_id, setSecDataID] = useState("");
  const [da_value, setValue] = useState({
    job_visibility: "",
    available_career_service: "",
    gotjob: "",
  });

  const counts = async () => {


    if(role_id==constants.USER_ROLES.AGENT)
    {
      fetch(`${API_URL}sidebar`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((responseData) => {
        console.log(responseData.data, "sssss");
        setDaval(responseData.data)
        setCustomercount(responseData);
        return responseData;
      })
      .catch((e) => {
        console.log(e);
      });


      
    }
     
  }

  const toggleSidebar = (values) => {
    setSidebarOpen(values);
  };

const setSecDataIdFun = (payload) => {
    setSecDataID(payload);

    api
      .get(`second_followup_data?dwms_profile_id=${payload}`, true)
      .then(async function ([success, response]) {
        if (response?.status === "success") {
          console.log(response, "iudjfdfuefheh");

          setValue({
            job_visibility: response?.data?.[0]?.job_visibility,
            available_career_service: response?.data?.[0]?.intrested_in_career_support,
            gotjob: response?.data?.[0]?.job_visibility,
          });

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const requestOptions = {
    method: "GET",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("AUTH_TOKEN") + "",
      "Accept": "application/json"
    },

    //  body,
  };


  const tab = async () =>{
    setTabopen(!tabopen)
  }

  const viewdisable = async () =>{
    
    setDisabled(!disabled)
    if(disabled == true){
        document.getElementById('details').style.display="none"
        document.getElementById('det').style.display="none"


    }
    else{
        document.getElementById('details').style.display="block"

    }


}

const viewch = async ()=>{
  setDisabled(false)
  console.log(disabled,"hhhhhhhhhhh")
}


const viewdisab = async () =>{
    
   
        document.getElementById('details').style.display="none"
        document.getElementById('det').style.display="none"
        document.getElementById('viewss').style.cursor= "allowed"
              document.getElementById('viewss').style.opacity="1"


}

const viewdisables = async () =>{
    
    if(disabled == false){
        setDisab(!disab)
    }
    if(disab == true ){
        document.getElementById('det').style.display="none"

    }
    else{
        document.getElementById('det').style.display="block"

    }


}


const changeTry = async () =>{
    setTri(!tri)
    // setTabopen(0)

}

const changeTry1 = async () =>{
    setTri1(!tri1)
    // setTabopen(0)


}

const closeallstates = ()=>{
    setTabopen(0)
    setTri(false)
    setTri1(false)
    setDisab(false)
    setDisabled(false)
}


  


  const mem = useMemo(() => {
    
  }, [firstcallcount, customercount,firstcallfollowupcount, tabopen, tab,secondcallfollowupcount, viewdisab,callcount,disabled,viewdisable ,daval,disab,viewdisables,changeTry,tri,closeallstates,changeTry1,tri1]);

  useEffect(() => {

    var coun = 5000

    setTimeout(()=>{

      counts()
    },coun)

    coun = 150000
    
  }, [mem])

  console.log()
  return (
    <SidebarContext.Provider value={{ 
                                      counts,
                                      sidebarOpen, 
                                      toggleSidebar,
                                       tab,
                                       tabopen, 
                                       firstcallcount,
                                        customercount, 
                                        firstcallfollowupcount,
                                        secondcallfollowupcount,
                                        disabled,
                                        viewdisable,
                                        disab,
                                        viewdisables,
                                        tri,
                                        changeTry,
                                        closeallstates,
                                        changeTry1,
                                        tri1,
                                        viewdisab,
                                        viewch,
                                        daval,
                                        sec_data_id,
                                        da_value,
                                        setSecDataIdFun
                                         }}>
      {children}
    </SidebarContext.Provider>
  );
}

export { SidebarContext, SidebarProvider };