import React from "react";
import {
  Button,
  List,
  Table,
  Form,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  FormGroup,
  Label,
} from "reactstrap";
import TextField from "../../../components/Fields/TextField";
import DropDown from "../../../components/Fields/DropDown";
const EditAgentModal = ({
  isShowings,
  hide,
  onChange,
  saveTriggers,
  setValidStatuss,
  validStatuss,
  USER_DATA,
  errors,
  values,
  setValues,
  setError,
  handleSubmitEdit,
}) => {
  const handleHide = () => {
    setError({})
    hide(false)
  }
  return (
    <React.Fragment>
      <Modal
        className="modal-outer"
        isOpen={isShowings}
        style={{ maxWidth: "880px" }}
      >
        <ModalHeader toggle={handleHide} className="primary border-bottom-0 px-5 pt-5">
          Edit Intern
        </ModalHeader>
        <ModalBody className="px-5 pb-5">
          <div class="upload-prod-outer">
            <Form className="form-wrap">
              <Row>
                {/* <Col md={12}>
                  <FormGroup>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </FormGroup>
                </Col> */}
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Intern name</Label>
                    <TextField
                      name="agent_name"
                      type="name"
                      placeholder="Agent name"
                      value={values?.agent_name}
                      onChange={onChange}
                      saveTrigger={saveTriggers}
                      validStatus={validStatuss}
                      setValidStatus={setValidStatuss}
                      setValue={setValues}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>User name</Label>
                    <TextField
                      name="intern_name"
                      type="text"
                      placeholder="User Name"
                      value={values?.intern_name}
                      onChange={onChange}
                      saveTrigger={saveTriggers}
                      validStatus={validStatuss}
                      setValidStatus={setValidStatuss}
                      setValue={setValues}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3 ">
                    {errors?.intern_name}
                  </div>
                </Col>
                <Col md={6} sm={6} className="">
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Mobile Number</Label>
                    <TextField
                      name="mobile_number"
                      type="phone"
                      placeholder="Mobile Number"
                      value={values?.mobile_number}
                      onChange={onChange}
                      saveTrigger={saveTriggers}
                      validStatus={validStatuss}
                      setValidStatus={setValidStatuss}
                      setValue={setValues}
                      required
                      max={10}
                      min={10}
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3 ">
                    {errors?.phone_no}
                  </div>
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="position-relative">
                    {" "}
                    <Label>Email Id</Label>
                    <TextField
                      name="email"
                      type="email"
                      placeholder="Email Id"
                      value={values?.email}
                      onChange={onChange}
                      saveTrigger={saveTriggers}
                      validStatus={validStatuss}
                      setValidStatus={setValidStatuss}
                      setValue={setValues}
                      required
                      className="form-control w-100 py-3"
                    />
                  </FormGroup>
                  <div style={{ color: "red" }} className="errors mt-3 ">
                    {errors?.email}
                  </div>
                </Col>
                <Col md={6} sm={6}>
                  <FormGroup className="select-wrap position-relative">
                    {" "}
                    <Label>Select Role</Label>
                    <DropDown
                      value={
                        USER_DATA?.find((wa) => {
                          return wa?.value == values?.role_id;
                        }) || null
                      }
                      saveTrigger={saveTriggers}
                      validStatus={validStatuss}
                      setValidStatus={setValidStatuss}
                      data={USER_DATA}
                      name="role_id"
                      onChange={onChange}
                      required
                    />
                  </FormGroup>
                </Col>
                <Row>
                  {/* <Col md={12}>
                    <FormGroup>
                      <div className="agree-checkbox-wrap d-flex align-items-center gap-2">
                        <Input type="checkbox" />
                        <Label className="mb-0">
                          Lorem Ipsum is simply dummy text of the printing{" "}
                        </Label>
                      </div>
                    </FormGroup>
                  </Col> */}
                  <Col md={12}>
                    <div className="d-flex align-items-center gap-4 justify-content-between mt-4">
                      <FormGroup className="mx-auto">
                        {values?.view == 1 ? null : (
                          <Button
                            color="primary"
                            className={"px-5 modal-btn"}
                            onClick={(e) =>
                              handleSubmitEdit(e, values?.edit, values?.user_id)
                            }
                          >
                            Update Intern
                          </Button>
                        )}
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
              </Row>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};
export default EditAgentModal;